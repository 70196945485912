.couponLabel{
    color: #2C2E2F;
    font-size: 16px;
    font-weight: 600;
    margin-top: 1.2rem;
}
.couponDescription{
    color: #2C2E2F;
    font-size: 16px;
    font-weight: 400;
}