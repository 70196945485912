.wishlistCard {
  width: 100%;
  height: auto;
  min-height: 135px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: var(--White, #fff);
  box-shadow: 0px 4px 6px -2px rgba(93, 95, 204, 0.03),
    0px 12px 16px -4px rgba(93, 95, 204, 0.08);
  padding: 16px;
  margin-bottom: 12px;
}
.active {
  border: 1px solid #5d5fcc;
}
.cardWrapper {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.productTitle {
  font-size: 22px;
  font-weight: 700;
  color: #18191f;
}
.productImage {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
}
.productContainer {
  display: flex;
  gap: 1.5rem;
  flex: 1;
}
.price {
  color: #fe8f01;
  font-size: 18px;
  font-weight: 700;
}
.nameContainer {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  flex: 1;
}

.innerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cartbutton {
  display: flex;
  align-items: center;
  gap: 12px;
}
.cartbutton button {
  border-radius: 2.969px;
  border: 0.642px solid rgba(0, 0, 0, 0.2);
  background: #f2f2f2;
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .productTitle {
    font-size: 16px;
    font-weight: 700;
    color: #18191f;
  }
  .price {
    color: #fe8f01;
    font-size: 16px;
    font-weight: 700;
  }
  .productContainer {
    display: flex;
    gap: 0.7rem;
  }
}
