.text__primary{
    color: $purple;
}
.text__black{
    color: $color-black !important;
}
.bold{
    font-weight: 600 !important;
}
.h__full{
    height: 100vh;
}
.page__container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}
.page__title{
    margin: .6rem 0rem;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}
.require__field{
    color: #FF0000;
}
a{
    &:hover{
        text-decoration: none;
    }
}

.border__wrapper{
    border: 1px dashed rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   label{
    width: 100%;
    padding: .5rem;
   }
}
.file__upload__wrapper{
    display: grid;
    gap: 1rem 2rem;
    grid-template-columns: repeat(auto-fit, minmax(219px, 1fr)); 
    overflow: hidden;
    // display: flex;
    // gap: .4rem 1rem;
    // align-items: stretch;
    // flex-wrap: wrap;
    // @include desktop{
    //     gap: 1rem 2.5rem;
    // }
    // > *:not(:last-child){
    //     flex: 1;
    // }
    //justify-content: space-between;
}
.file__uploader__wrapper{
    display: flex;
    gap: .5rem;
    justify-content: center;
    cursor: pointer;
    min-height: 6.4rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 1rem 1.8rem;
    box-sizing: border-box;
    align-items: center;


    .upload__button{
        color: $purple;
        cursor: pointer;
    }
    
    .title{
        color: $color-black;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
    }
    .types{
        font-size: 14px;
        line-height: 19px;
        text-align: center;
    }
    .image__sizing{
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
    }

}

//dshboard layout style
.dashboard__layout{
    box-sizing: border-box;
    display: flex;
    gap: .6rem;
    background: #f2f2f2;
    //welcome header
    .header__section__wrapper{
        margin: 2rem 0rem;
        display: flex;
        justify-content: space-between;
        .user__info{
            .title{
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color:#9598A4;
                span{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #495057;
                }
            }
            .description{
                margin-top: .2rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #9598A4
            }
        }
        a{
            &:hover{
                text-decoration: none;
            }
        }
    }
    .search__product__action__wrapper{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background:$primary-gray;
        flex-wrap: wrap;
        border-radius: 5px;
        padding: 1rem 1.5rem;
        @include desktop{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background:$primary-gray;
            border-radius: 5px;
            padding: 1rem 1.5rem;
        }
        .actions__wrapper{
            display: flex;
            gap: 1rem;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color:$color-black;
            .disabled{
                color: #B0B0B0;
            }
        }
    }
    .main__content{
        background: #ffff;
        margin-left: 0px;
        width: 100%;
        overflow: auto;
        min-height: 100vh;
        padding-bottom: 6rem;
        @include desktop{
        background: #ffff;
        transition: margin-left 0.5s;
        margin-left: 260px;
        width: 100%;
        overflow: auto;
        height: 100%;
        padding-bottom: 2rem;
        }
       
        .content{
            padding: .5rem 1rem;
            @include desktop{
                padding: .5rem 1.5rem;
            }
        }
    }
    .main__content__fixed{
        margin-left: 0px;
    }
    .empty__state{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
    }

   
    
     
}