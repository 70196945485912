.settings{
    .settings__wrapper{
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12rem;
        @include desktop{
            flex-wrap: nowrap;
            margin-bottom: 12rem;
        }
        .settings__sidebar{
            width: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            padding: 1.5rem 0rem .5rem 1.5rem;
            @include desktop{
                width: 50%;
                border-right: 1px solid rgba(0, 0, 0, 0.15);
                padding: 1.5rem 0rem 6rem 1.5rem;
            }
            .title{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #21252A;
            }
            .step{
                margin: 2rem 0rem;
                display: flex;
                gap: 1rem;
                align-items: center;
                .step__title{
                    
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(24, 25, 31, 0.7);
                }
                .round__unchecked{
                    width: 16px;
                    height: 16px;
                    border: 1px solid rgba(24, 25, 31, 0.25);
                    border-radius: 50%;
                }
            }
        }
        .settings__content{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: auto;
            .content{
                margin-bottom: 3rem;
                width: 100%;
                max-width: 700px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #21252A;
                @include desktop{
                width: 100%;
                max-width: 500px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #21252A;
                }
                .title{
                    margin: .5rem;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    @include desktop{
                        margin: .5rem;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                    
                    }
                    
                }
                .description{
                    margin-bottom: 2rem;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    @include desktop{
                        margin-bottom: 2rem;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                    }
                    
                }
                .button__wrapper{
                    display: flex;
                    gap: 1rem;
                  
                    button{
                        padding:.8rem 1.6rem;
                    }
                    @include desktop{
                        display: flex;
                    gap: 2rem;
                    button{
                        padding:1.125rem 2.75rem;
                    }
                    }
                }
                
                //premium plan
                .premium__plan__wrapper{
                    border: none;
                    padding: .5rem;
                    margin: 2rem auto;
                    @include desktop{
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        padding: 1.5rem;
                    }
                    .plan_head{
                        .plan__title{
                            font-weight: 800;
                            font-size: 16px;
                            line-height: 19px;
                            color:$color-black;
                        }
                        .price{
                            margin-top: 0.75rem;
                            .amount{
                                font-weight: 800;
                                font-size: 20px;
                                line-height: 24px;
                                color: $color-black;
                            }
                            .term{
                                margin-left: .3rem;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: rgba(24, 25, 31, 0.5);
                            }
                        }
                    }
                    .plan__content{
                        margin: 1rem auto;
                        @include desktop{
                            margin: 2rem auto;
                        }
                        .plan__item{
                            display: flex;
                            gap: .2rem 1rem;
                            align-items: center;
                            padding: .6rem;
                            @include desktop{
                                gap: 1rem;
                            }
                            .plan__text{
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 19px;
                                color: #18191F;
                                @include desktop{
                                    font-size: 14px;
                                    line-height: 28px;
                                }
                            }
                        }
                        
                    }
                }
                .skip{
                    margin: 1.25rem auto;
                    color: $purple;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    cursor: pointer;
                }
                
            }
        }
    }
    .bank__detail{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #219653;
       

    }
}