$primary-color:#FE8F01;
.mobile__nav__wrapper{
    a{
        &:focus{
            color: inherit;
        }
        &:active{
            color: inherit;
        }
    }
    .mobile__nav{
        height: 61px;
        background: #18191F;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;

}
.cart__quantity{
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 28px;
    max-height: 28px;
    background: $primary-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    right: 10px;
    top: 4.2rem;
}
}
