.auth {
  padding: 40px 16px 56px 16px; // 72px is 60px for alert + 12px
  @include desktop {
    padding: 62px 0px 56px 0px; // 72px is 60px for alert + 12px // could later return it to
  }
  &-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
  }
  &-register {
    width: 100%;
    height: auto;
    margin: 0px auto;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    display: flex;
    flex-direction: column;

    @include desktop {
      width: 848px;
      height: 869px;
      flex-direction: row;
    }

    &__form-section {
      width: 100%;
      height: inherit;
      background-color: $white;
      padding: 40px;
      position: relative;
      top: -5px;
      border-top-right-radius: $border-radius-2;
      border-top-left-radius: $border-radius-2;
      @include desktop {
        width: calc(848px / 2);
        top: unset;
        position: unset;
      }
    }

    &__info-section {
      width: 100%;
      height: inherit;
      background-color: $black;
      padding: 40px;
      @include desktop {
        width: calc(848px / 2);
      }

      &-header {
        width: 100%;
        font-family: $secondary-font;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;
        color: $white;
        text-align: center;
        margin-bottom: 12px;
        @include desktop {
          width: 317px;
          text-align: left;
        }
      }
      &-detail {
        width: 100%;
        opacity: 0.8;
        font-size: $font-size-2;
        line-height: 1.71;
        color: $white;
        margin-top: 12px;
        margin-bottom: 16px;
        text-align: center;
        @include desktop {
          width: 328px;
          text-align: left;
        }
      }
      &-feature__list {
        display: none;
        flex-direction: column;
        margin-top: 36px;
        @include desktop {
          display: flex;
        }
        &-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: $font-size-2;
          font-weight: normal;
          color: $white;
          margin: 12px 0px;
          &:first-child {
            margin-top: 0px;
          }
          &:last-child {
            margin-bottom: 0px;
          }

          &__icon {
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            object-fit: contain;
            background: $white;
            border-radius: 50%;
            margin-right: 13px;
          }
        }
      }
    }
  }
  &-panel {
    width: 100%;
    height: 610px;
    margin: 0px auto;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    background-color: $white;
    border-radius: $border-radius-2;
    padding: 24px 0px;
    @include desktop {
      width: 373px;
    }

    &--alternate {
      height: auto;
    }

    &__head {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 24px 24px 24px;
      border-bottom: solid 1px $gainsboro;

      &-header {
        font-size: $font-size-5;
        line-height: 1.33;
        text-align: center;
        color: $dark;
        margin-bottom: 8px;
        @include medium-font;
      }
      &-help {
        font-size: $font-size-2;
        line-height: 1.43;
        text-align: center;
        color: #5a3e22;
        margin-top: 8px;

        &--alternate {
          background-color: #fef9e8;
          padding: 16px 24px;
          border-radius: $border-radius-2;
          text-align: left;
          // &:hover{
          //     box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
          // }
        }
      }
    }
    &-divider {
      display: flex;
      flex-direction: row;
      place-items: center;
      margin: 24px 0px;
      &__line {
        height: 1px;
        background: $gainsboro;
        flex-basis: 50%;
      }
      &__circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $gainsboro;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #707479;
        font-weight: 800;
      }
      &-text {
        font-size: $font-size-xs;
        @include medium-font;
      }
    }
    &-main {
      padding: 24px;
      & .button-facebook {
        margin-bottom: 8px;
      }
    }
  }

  &-terms-condition {
    font-size: $font-size-2;
    color: $black;
    margin-top: 24px;
    text-align: center;
    @include desktop {
      margin-top: 12px;
      text-align: left;
    }

    & a {
      color: $orange;
    }
  }

  &-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    &-label {
      font-size: $font-size-2;
      font-weight: 500;
      color: $dark;
      &--alternate {
        opacity: 0.5;
      }
    }
    & .pill {
      margin-top: 16px;
    }
  }
}
