
.tab-header{
    display:flex;
    .tab-active{   
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #5E60CE;
        margin-right: 15px;
        cursor: pointer;
        border-bottom: solid 2px #5E60CE
    }

    .non-active{   
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-right: 15px;
        cursor: pointer;
    
    }
}
.tab-content{
    width: 100%;
    overflow-y: auto;
    height: 300px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    .headtitle{
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
    }
    .no-transaction{
        margin: 60px;
    }
  .transaction{
   border: 1px solid rgb(218, 214, 214);
   display: flex;
   flex-direction: column;
   -ms-flex-pack: center ;
   padding: 8px;
   margin:4px;
   justify-content: space-between;
   border-radius: 5px;

  .raise-issue{
    display: flex;
    align-self: flex-end;
    background-color: #0e8092;
    color: #fff;
    border-radius: 3px;
    justify-content: center;
    justify-self: end;
    border: none;
    width: 70px;
    padding: 2px;
    font-size: 10px;
    margin-bottom: 2px;
  }

   .datawrapper{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 2px 0px 2px 0px ;
    .title{
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
       }
       .data{
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        margin-top: 3px;
       }
       .debit{
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        margin-top: 3px;
        color: red;
       }

   }


    }

}