$font-family_1: 'Inter';

.featured-stores {
	box-sizing: border-box;
	margin: auto auto;
	padding: 7px 5px 7px;
	font-family: $font-family_1;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include desktop{
		font-family: $font-family_1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 15px 15px;
	}

	.title {
		font-weight: 800;
        font-size: 18px;
        line-height: 22px;

		@include desktop{
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 800;
		font-size: 28px;
		line-height: 34px;
		}
	}
	.text {
			font-weight: 400;
            font-size: 10px;
            line-height: 12px;

		@include desktop{
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			margin-top: 8px;
		}
	}
	.more {
		font-weight: 500;
        font-size: 15px;
        line-height: 18px;

		@include desktop{
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			cursor: pointer;
			text-decoration: none;
		}
	}
}

// .store-image{
// 	width:7.5rem;
// 	height: 5.591rem;
// 	background-color: aliceblue;
// }
.store-card-container{
	gap:.1rem 5rem;
	@include desktop{
		gap:.4rem 3rem;
	}
}

.buyer-store-card{
	box-sizing: border-box;
	display: flex;
	gap: 5px 15px;
	width: 100%;
	max-width: 24rem;
	margin:1rem auto ;
	@include desktop{
		box-sizing: border-box;
		display: flex;
		gap: 10px;
		//width: 100%;
		width: 21.5rem;
		margin:1rem auto ;
		&:hover{
			background: white;
			box-shadow: 1px 10px 26px 4px rgba(0, 0, 0, 0.12);
		}
	}
	
	
	.title{
		font-style: normal;
		font-weight: 600;
		font-size: 1.125em;
		line-height: 22px;
		color: #18191F;
		white-space:inherit;
	}
	.description{
		font-style: normal;
		font-weight: 400;
		font-size: 0.625em;
		line-height: 17px;
		color: #18191F;
		
	}
	.link{
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		cursor: pointer;
	}
	.store-image{
		height: 5.591rem;
		width: 7.5rem;
		image-rendering:crisp-edges;
		background-color: aliceblue;
		img{
			object-fit:cover;
			height: 5.591rem;
			width: 7.5rem;
		}
	}
	// .store-information{
	// 	align-self: flex-end;
	// 	justify-self: flex-end;
	// }
}