.text{

    &--xs{
        font-size: $font-size-xs;
        color: $black;
    }
    &--small{
        font-size: $font-size-3;
        color: $black;
    }

    &--smaller{
        font-size: $font-size-2;
        font-weight: normal;
        color: $black;
    }
    &--smallest{
        font-size: $font-size-1;
        color: $black;
    }

    &--md{
        font-size: $font-size-5;
        color: $black;
    }
    &--big{
        font-size: $font-size-8;
    }


    // text colors
    &-font--medium{
        font-family: $medium-font;
        font-weight: 500;
    }

    &--white{
        color: $white;
    }

    &--gray{
        color: $gray;
    }
    &--dark{
        color: $dark;
    }

    &--orange{
        color: $orange !important;
    }
    &--silver{
        color: $silver !important;
    }

    &--ellipsis{
        width: 100%;
        height: 18px;
         overflow: hidden;
         display: -webkit-box;
         -webkit-line-clamp: 1;
         -webkit-box-orient: vertical;
    }

    &--bold{
        font-weight: 500;
    }

    &--center{
        text-align: center;
    }





    &--active{
        color: $refund-text-color;
    }
    &--blocked{
        color: $closed-text-color;
    }
    &--pending{
        color: $pending-text-color;
    }
    &--awaiting{
        color: $awaiting-text-color;
    }
    &--initial{
        color: $initial-text-color;
    }
    &--open{
        color: $open-text-color;
    }
    &--fulfilled{
        color: $fulfilled-text-color;
    }
    &--flagged{
        color: $flagged-text-color;
    }
    &--closed{
        color: $closed-text-color;
    }
    &---refund{
        color: $refund-text-color;
    }
    &--release{
        color: $release-text-color;
    }
    &--stop{
        color: $stop-text-color;
    }
}

.status-text{
    font-weight: 600;
}