$color_1: transparent;
$color_2: rgba(0, 0, 0, 0);
$color_3: rgb(168, 167, 167);
$color_4: white;
$main_color:#FE8F01;

/* .slick-slide {
}

.slick-track {
} */
.slick-arrow {
	display: block;
}



.slider-backward-arrow {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	width: 38px;
	height: 38px;
	border: 1px solid #18191F59;
	border-radius: 10px;
	color: $color_3;
	position: absolute;
	margin-top: .2rem;
	color: $color_4;
	right: 4rem;
	font-size: 2rem;
	text-align: center;
	cursor: pointer;
	.icon{
		margin-top: .8rem;
	}
	@include desktop{
		cursor: pointer;
		font-size: xx-large;
		height: auto;
		width: max-content;
		color: $color_3;
		position: absolute;
		margin-top: 7.3rem;
		background: $main_color;
		padding: .5rem .3rem;
		color: $color_4;
		border:none;
		left: 10px;
		font-size: 2rem;
		border-radius: 0px;
	}
}
.slider-forward-arrow {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: xx-large;
	width: 38px;
	height: 38px;
	border: 1px solid #FE8F01;
	border-radius: 10px;
	color: $color_3;
	position: absolute;
	margin-top: .2rem;
	color: $color_4;
	right:10px;
	font-size: 2rem;
	text-align: center;
	cursor: pointer;
	.icon{
		margin-top: .8rem;
	}
	@include desktop{
		font-size: xx-large;
		color: $color_3;
		height: auto;
		width: max-content;
		position: absolute;
		margin-top: 7.3rem;
		background: $main_color;
		color: $color_4;
		padding: .6rem .4rem;
		right: 10px;
		font-size: 2rem;
		border-radius: 0px;
		cursor: pointer;
	}
}