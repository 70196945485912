.onboarding__layout{
    display: flex;
    flex-wrap: wrap;
    .onboarding__sidebar{
        padding: 2rem 1rem;
        width: 100%;
        background: #ffff;
        height: auto;
        @include desktop{
            padding: 2rem;
            width: 25%;
            background: #ffff;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100vh;
        }
        .header__section{
            margin: 2rem 0rem;
            .title{
                font-weight: 800;
                font-size: 24px;
                line-height: 36px;
                color:$color-black;
            }
            .description{
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $purple;
            }
            .progress__section{
                margin: .7rem 0rem;
                gap: 10px;
                align-items: center;
            }
            .percent{
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: $color-black;
            }
        }
    }
    .main__content{
        width: 100%;
        padding: 1rem auto;
        @include desktop{
            width: 75%;
            padding: 1rem;
            overflow: auto;
            flex: 1;
            margin-left:25%;
        }
    }
}