.signUp {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: red;
  display: grid;
  grid-template-columns: 1fr;
  background: url("/assets/images/big-laptop-guy-bg.png") no-repeat center center;
  object-fit: cover;
  background-size: cover;
  position: relative;

  @include desktop {
    padding: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }

  & > div:nth-child(2),
  div:nth-child(3) {
    z-index: 1;
  }

  & > div:nth-child(2) {
    display: none;

    @include desktop {
      display: block;
    }
  }

  & > div:nth-child(2) > .signUp__LeftSideBox {
    margin-top: 7rem;
  }
  & > div:nth-child(2) > .signUp__LeftSideBox2 {
    margin-top: 3rem;
  }
  & > div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }

  > div:nth-child(3) > main {
    background: white;
    height: 100vh;
    width: 100%;
    border-radius: 0rem;
    padding: 2rem 2rem 1rem 2rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      // width: 10px;
      // border-radius: 2.5px;
    }

    &::-webkit-scrollbar-track {
      background: $lightOrange;
      width: 10px;
    }

    @include desktop {
      border-radius: 0.6rem;
      width: 70%;
      height: 90vh;
    }
  }

  .overlay {
    left: 0px;
    top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(254, 143, 1, 0.4);
    overflow: hidden;
    z-index: 0;
    transition: all 0.2s ease-in-out;
  }

  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 82px;
    text-align: left;
    color: #ffffff;
  }

  &__right__header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 44px;
    color: #18191f;
    margin-bottom: 1rem;

    @include desktop {
      font-size: 2rem;
    }
  }

  &__btn {
    width: 100%;
    background: $lightOrange;
    padding: 0.8rem;
    border: 0;
    outline: none;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    border-radius: 35px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
  }
}

.orangeText {
  color: $lightOrange !important;
}
