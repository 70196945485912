.confirmation {
  min-height: 80vh;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    padding: 0px 16px;
    width: 100%;
    height: auto;
    @include desktop {
      padding: 0;
    }
  }

  &--status {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $refund-tag;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    & .icon {
      path {
        fill: #6cda7e;
      }
    }
  }

  &-card {
    width: 325px;
    height: auto;
    border-radius: $border-radius-2;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    background-color: $white;
    @include desktop {
      height: auto;
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 1px dashed $border-color-1;
      padding: 16px 0px;
    }

    &-body {
      &-status {
        padding: 24px 32px;
        border-bottom: 1px dashed $border-color-1;
      }

      &-order__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 32px;
        border-bottom: 1px dashed $border-color-1;

        & .button {
          margin-top: 8px;
        }
      }

      &__base {
        display: flex;
        flex-direction: column;
        padding: 24px 32px;

        &-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 16px;

          & .button {
            margin: 0px 8px;
            &:first-child {
              margin-left: 0px;
            }
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

.receiptTable {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  font-size: 13px;
  column-gap: 5px;
  justify-content: space-between;
  width: 100%;
}
