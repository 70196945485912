.productSummary {
  border-radius: 8px;
  background: #f5f7ff;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 1rem 0rem;
}
.summaryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summaryItem .value {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #18191f;
}
.summaryItem label {
  color: rgba(24, 25, 31, 0.55);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
