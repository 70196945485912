.pill{
    height: 48px;
    border-radius: $border-radius-lg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $dark;
    font-size: $font-size-2;
    cursor: pointer;
    text-decoration: none !important;
    @include medium-font;
    &:hover{
        color: $dark;
    }

    &-xs{
        width: 100%;
       @include desktop{
            width: 328px;
       }
    }

    &--primary{
        background-color: $lavender;
    }
}