$background-color_1: #fff;
$background-color_2: #219653;
$border-color_1: #219653;

/* Base for span styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + span,
[type="checkbox"]:checked + span {
  position: relative;
  padding-left: 16px;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + span:before,
[type="checkbox"]:checked + span:before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid $gray;
  background: $white;
  border-radius: 4px;
  transition: all .2s ease-in-out;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + span:after,
[type="checkbox"]:checked + span:after {
  content: url('/assets/images/svg/white-tick.svg');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  position: absolute;
  left: -10px; 
  top: 0;
  padding-bottom: 2px;
  color: $white;
  background: $purple;
  transition: all .2s ease-in-out;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + span:after {
  opacity: 0;
  // transform: scale(0);
}
[type="checkbox"]:checked + span:after {
  opacity: 1;
  // transform: scale(1);
}
// /* disabled checkbox */
// [type="checkbox"]:disabled:not(:checked) + span:before,
// [type="checkbox"]:disabled:checked + span:before {
//   box-shadow: none;
//   border-color: #bbb;
//   background-color: #ddd;
// }
// [type="checkbox"]:disabled:checked + span:after {
//   color: #999;
// }
// [type="checkbox"]:disabled + span {
//   color: #aaa;
// }
// /* accessibility */
// [type="checkbox"]:checked:focus + span:before,
// [type="checkbox"]:not(:checked):focus + span:before {
//   border: 2px dotted blue;
// }

/* hover style just for information */
span:hover:before {
  border: 1px solid $purple !important;
}

