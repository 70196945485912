.referer__page{
    font-family: 'Inter' !important;
    margin: 0 !important;
    background: #F5F5F5;

    .content__wrapper{
        margin: 3rem 0rem;
        padding: 1.5rem;
        @include desktop{
            margin: 3rem 0rem;
        }
        .header__section{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 600px;
            margin: .5rem auto;
            gap: .6rem;
            .title{
                text-align: center;
                font-style: normal;
                font-weight: 800;
                font-size: 30px;
                line-height: 32px;
                color: $color-black;
                @include desktop{
                text-align: center;
                font-style: normal;
                font-weight: 800;
                font-size: 38px;
                line-height: 46px;
                color: $color-black;
                }
            }
            .detail{
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;   
                text-align: center;
            }
        }
        .reward__container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            max-width: 60rem;
            padding: 1rem;
            margin: .4rem auto;
            @include desktop{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            max-width: 60rem;
            padding: 2rem;
            margin: .4rem auto;
            }
            
            .reward__wrapper{
                margin: 1rem auto;
                width: 100%;
                max-width: 16rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: .4rem;
                text-align: center;
               @include desktop{
                margin: 2rem auto;
                width: 100%;
                max-width: 16rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: .4rem;
                text-align: center;
               }
                .icon__wrapper{
                    width: 72px;
                    height: 72px;
                    border-radius: 100px;
                    background: #5D5FCC1A;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .title{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: $color-black;
                }
                .description{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: $color-black;
                }
                
            }
        }
        .invite__section{
           .header__section{
            width: 100%;
            max-width: 40rem;
            margin: 2rem auto;
            .title{
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: $color-black;
              @include desktop{
                font-weight: 800;
                font-size: 24px;
                line-height: 29px;
                color: $color-black;
              }
            }
            .description{
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: center;
                color: $color-black;
            }
           }
           .input__with__icon{
            position: relative;
            width: 100%;
            max-width: 46rem;
            margin: 2rem auto;
            
            .input__wrapper{
                position: relative;
                width: 100%;
                max-width: 46rem;
                margin: 2rem auto;
                input{
                    padding-right: 6rem;
                }
            }
            .input__icon{
                position: absolute;
                right: 1.2rem;
                top: 1.3rem;
                cursor: pointer;
                
            }
           }

            
        }
        .share__section{
            margin: 1rem 0rem;
            padding: 1rem 0rem;
         
            .header__section{
                .title{
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    color: $color-black;
                  @include desktop{
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 29px;
                    color: $color-black;
                  }
                }
                .description{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    color: $color-black;
                    text-align: center;
                }
            }

            .referer__link__wrapper{
                margin: 2rem auto;
                width: 100%;
                max-width: 45rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                @include desktop{
                margin: 3rem auto;
                width: 100%;
                max-width: 47rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                }
              

                .referer__link{
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: $purple;
                background-color: #5D5FCC26;
                border-radius: 10px;
                display: flex;
                gap: 1rem;
                padding: .8rem;

                @include desktop{
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: $purple;
                background-color: #5D5FCC26;
                border-radius: 10px;
                display: flex;
                gap: 4rem;
                padding: .8rem;
                }
                .copy{
                    font-weight: 800;
                    cursor: pointer;
                }
                }
            }
            .share__wrapper{
                display: flex;
                gap: 1rem;
                .icon{
                    width: 61px;
                    height: 59px;
                    background: #5D5FCC26;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}