.social__pages{
    margin: 3rem auto;
    height: 100%;
    width: 100%;
    max-width: 1017px;
    padding-bottom: 12rem;
    .page__header{
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #495057;
        @include desktop{
            font-size: 24px;
            line-height: 29px;
        }
    }
    .pages__wrapper{
        margin: 2rem auto;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    .sidebar__text{
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 1.8rem 1.5rem;
        @include desktop{
            max-width: 332px;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
        .title{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #21252A;
        }
        .description{
            margin: .5rem 0rem;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            color: #21252A;
        }
    }
    .pages__container{
        width: 100%;
        padding: 1.8rem 1.5rem;
        @include desktop{
            margin-left: 3rem;
            max-width: 400px;
        }
        .title{
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: #21252A;
        }
        .empty__page__text{
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #495057;
       }
        .page__list{
            margin: 1.5rem 0rem;
           
            .page__item{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #21252A;
                display: flex;
                gap: 1rem;
                align-items: baseline;
            }
        }
        .action__button__wrapper{
            padding-bottom: 4rem;
        }
    }
    
}