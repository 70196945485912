$primary-color:#5D5FCC;
$secondary-color:#F2F2F2;
$color-black:#18191F;
$color-orange:#FE8F01;
$white-200:#F2F2F2;
.text__primary{
    color: $primary-color !important;
}

.text__black{
    color: $color-black;
}
.text__orange{
    color: $color-orange;
}
.text__primary{
    color: $primary-color;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    from{
        transform: translateX(-500px);
    }
    to{
        transform: translateX(0);
    }
    
}
.slideInLeft{
    animation: slideInLeft 1s ease-in;
}
.fadeIn{
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes slideInRight {
    from{
        transform: translateX(500px);
    }
    to{
        transform: translateX(0);
    }
    
}
@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

.slideInRight{
    animation: slideInRight 1s ease-in;
}

.logo{
    aspect-ratio: 1 !important;
}

.cookie__consent{
    position: fixed;
  bottom: 0;
  left: 0;
  width: 60%;
  background-color: #f5f5f5;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .2rem;
  font-size: 16px;
  line-height: 23px;
  @include desktop{
    width: 40%;
  }
//   @include desktop{
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     gap: 2rem;
//   }
  button{
    background-color: #5E60CE;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  
  }
  .policy__link{
    cursor: pointer;
        color: #FE8F01;
  }
}
.landing__page{
    font-family: 'Nunito' !important;
    background: #FFFFFF;
    margin: 0;
    padding: 0;
    
    
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
            color: $orange;
        }
    }
        font-family: 'Inter' !important;

    //overflow: auto;
    .button{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 48px;
        padding: 14px 18px;
        width: max-content;
        //height: 58px;
        border-radius: 10px;
        @include desktop{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 16px 30px;
        height: 52px;
        gap: 10px;
        width: max-content;
        //height: 58px;
        border-radius: 10px;
        }
        &__primary{
            background-color: $primary-color;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            @include desktop{
            background-color: $primary-color;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            }
        }
        &__white{
            background-color: white;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #18191F;
            @include desktop{
            background-color: white;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #18191F
            }
        }
        &__outline{
            background-color: white;
            font-weight: 500;
            font-size: 15px;
            border: 1px solid $primary-color;
            line-height: 18px;
            color: $primary-color;
           @include desktop{
            background-color: white;
            font-weight: 500;
            font-size: 18px;
            border: 1px solid $primary-color;
            line-height: 22px;
            color: $primary-color
           }
        }
    }
    

    .hero__section{
        height: auto;
       
        
    .hero{
        height: auto;
            margin-top: 3rem;
            
            //margin-bottom: 2.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            @include desktop{
            margin-top: 2.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: auto;
         
            // display: flex;
            // gap: 30px;
            // flex-direction: row;
            // justify-content: flex-start;
            // align-items: flex-start;
            margin-bottom: 0rem;
            }
            //justify-content: space-between;

            .image__section{
                
                transition: transform 0.3s;
                // img{
                //     width: 100%;
                //     height: 100%;
                // }
                
                @include desktop{
                    // img{
                    //     width: 100%;
                    //     height: 100%;
                    //     margin: 0px;
                      
                        
                    // }
                }
            }


            .text__section{
                text-align: center;
                width: 100%;
                max-width: 730px;
                padding-right:0px;
                margin-top: 2rem;
                @include desktop{
                width: 100%;
                max-width: 770px;
                padding-right:15px;
                margin-top: 4rem;
                text-align: left;
                }
               
                .header__text{
                    transition: opacity 5s;
                    text-align: left;
                    font-weight: 800;
                    font-size: 36px;
                    line-height: 48px;
                    letter-spacing: -0.025em;
                    color: #18191F;
                    .first__line, .second__line{
                        &::after{
                            content: ' ';
                            //white-space: pre;
                        }
                    }
                   

                    @include desktop{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 56px;
                    line-height: 75px;
                    color: #18191F;
                    text-align: left;
                    width: 680px;
                    .first__line, .second__line{
                        &::after{
                            content: '\a';
                            white-space:pre; 
                        }
                    }
                    
                  
                    }
                }
            }
            .coming__soon{
                margin-top: 3rem;
                margin-bottom: 3rem;
                p{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #18191F;
                    margin-bottom: .5rem;
                    @include desktop{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #18191F;
                    margin-bottom: .5rem;
                    text-align: left;
                    }
                }
                
                .store__download{
                    display: flex;
                    //gap: 8px;
                    justify-content: space-between;
                   @include desktop{
                    display: flex;
                    gap: 2rem;
                    justify-content: flex-start;
                   }
                }
            }
        }
    }

    .app__download__container{
        margin: 2rem 0rem;
        display: flex;
        gap: 1.125rem;
    }
    
    //header style
    .nav__wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        color: #18191F;
    }
    .header__navigation{
        display: flex;
        justify-content: space-between;
        align-items: center;
    .store__download{
        display: flex;
        gap: 2rem;
        align-items: center;
        a{
            text-decoration: none;
            &:hover{
                color: inherit;
            }
        }
    }
    }
    //buyer section
    .buyer__section{
        background: $white-200;;
        //background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.25) 102.15%), url('/assets/images/buyer-image.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;

        @include desktop{
        background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.25) 102.15%), url('/assets/images/buyer-image.png');
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 803px;

        }
        .header__section{
           margin: 5rem 0rem;
         
           @include desktop{
            margin: 1rem auto;
           }
            .header__title{
                margin-bottom: .8rem;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $color-black;
                @include desktop{
                margin-bottom: .8rem;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #FFFFFF;
                }
                }
                .description{
                    width: 100%;
                    max-width: 800px;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 32px;
                    color: $color-black;

                    @include desktop{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 56px;
                    line-height: 75px;
                    color: #FFFFFF;
                    width: 100%;
                    max-width: 800px;
                    }
                }
        }
        .buyer__image{
            height: 399px;
            padding: 0;
            margin: 0;
            img{
                width: 100%;
            }
            display: block;
            @include desktop{
                display: none;
            }
        }

        

    }

    .seller__section{
        background: $secondary-color;
        //background-image:none; //linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.25) 102.15%), url('/assets/images/seller-image.png');
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
       @include desktop{
        background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.25) 102.15%), url('/assets/images/seller-image.png');
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 803px;
       }

       .header__section{
        margin: 4rem 0rem;
        @include desktop{
        margin: 1rem auto;
        }
         .header__title{
             margin-bottom: .8rem;
             font-style: normal;
             font-weight: 400;
             font-size: 14px;
             line-height: 17px;
             color: $color-black;
             @include desktop{
             margin-bottom: .8rem;
             font-style: normal;
             font-weight: 400;
             font-size: 24px;
             line-height: 29px;
             color: #FFFFFF;
             }
             }
             .description{
                 width: 100%;
                 max-width: 800px;
                 font-style: normal;
                 font-weight: 800;
                 font-size: 22px;
                 line-height: 32px;
                 color: $color-black;

                 @include desktop{
                 font-style: normal;
                 font-weight: 800;
                 font-size: 56px;
                 line-height: 75px;
                 color: #FFFFFF;
                 width: 100%;
                 max-width: 800px;
                 }
             }
     }
     .seller__image{
        display: block;
        height: 399px;
        padding: 0;
            margin: 0;
            img{
                width: 100%;
            }
        @include desktop{
            display: none;
        }
    }

        

    }

    .ecommerce__section{
        text-align: left;
        background: $secondary-color;
        //background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.25) 102.15%), url('/assets/images/ecommerce-image.png');
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;

        @include desktop{
        text-align: center;
        background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.25) 102.15%), url('/assets/images/ecommerce-image.png');
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 803px;
        }

        .header__section{
        margin: 4rem auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        @include desktop{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 4rem;
        }
            .header__title{
                margin-bottom: .8rem;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $color-black;
                @include desktop{
                margin-bottom: .8rem;
                font-style: normal;
                font-weight: 400;
                font-size: 26px;
                line-height: 32px;
                color: #FFFFFF;
                }
                }
                .description{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 22px;
                    line-height: 32px;
                    color: $color-black;
                
                    @include desktop{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 56px;
                    line-height: 75px;
                    color: #FFFFFF;
                    width: 70%;
                    }
                }
        }
        .ecommerce__image{
            display: block;
            height: 399px;
            padding: 0;
            margin: 0;
            img{
                width: 100%;
            }
            @include desktop{
                display: none;
            }
        }

        

    }
    //peppa banner
    .peppa__banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        background: #5E60CE;
        color: white;
        @include desktop{
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        height: 394px;
        background: #5E60CE;
        color: white;
        }
        .hello{
            margin-top: 1rem;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            @include desktop{
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            }
        }
        .peppa__text__wrapper{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 2rem;
            @include desktop{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            padding: 2rem;
            }
        }
        .peppa__text{
            font-weight: 800;
            font-size: 36px;
            line-height: 32px;
          @include desktop{
            font-weight: 800;
            font-size: 72px;
            line-height: 87px;
          }
        }
        .peppa_description{
            margin-top: .8rem;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
    }
    .steps__container{
        padding: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        .steps__header{
            width: 100%;
            max-width: 483px;
            .title{
                font-weight: 800;
                font-size: 48px;
                line-height: 64px;
                color: #18191F;
                text-align: center;
            }
            .description{
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                text-align: center;
                color: #18191F;
            }
        }
        .steps__nav{
            margin-top: 3rem;
            display: flex;
            gap: 5rem;
            justify-content: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            p{
                cursor: pointer;
            }
            .active{
                border-bottom: 2px solid $primary-color;
                color: $primary-color;
                padding-bottom:.8rem ;
                font-weight: 600;
            }
        }
        .step__wrapper{
            position: relative;
            margin-top: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4rem;
            .step{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width:100%;
                max-width: 207px;
                text-align: center;
                p{
                    margin-top: .7rem;
                }
            }
        }
        .line-indicator{
           // position: absolute;
            //padding-top: 10rem;
            z-index: 1;
            background-color: red;
          
        }
    }


    //partners
    .partners{
        //margin-top: 2.5rem;
        box-sizing: border-box;
        @include desktop{
        //margin-top: 6.5rem;
        box-sizing: border-box;
        }
        .clients{
            margin-bottom:2rem;
            border-bottom:1px solid #E5E9F2;
            border-top:1px solid #E5E9F2;
            padding: 1rem 0rem;
            
            .client__title{
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #18191F;
                text-align: center;
            
                @include desktop{
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #18191F;
                text-align: center;
         
                }
            }
            
        }
        .partners__images{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
            gap: 1.4rem 2.6rem;
           justify-content: center;
            padding:1rem 0rem;
            margin: 3rem auto;

            .partner__image__wrapper{
                display: flex;
                align-self: center;
               // width:100%;
                @include desktop{
                display: flex;
                align-self: center;
                
                }
              
                
               
            }
        }

        .logo__slider{
            animation: 15s slide infinite linear;
        }
        
    }
   
    //testimonial section
    .comment__section{
        margin-top: 3rem;
        @include desktop{
            margin-top: 18rem;
        }
        display: flex;
        flex-direction: column;
        @include desktop{
            display: flex;
            flex-direction: row;
        }
        
        .comment__text{
            background: #5E60CE;
            padding: 2rem 1.3rem;
            width: 100%;
            color: white;
            @include desktop{
            background: #5E60CE;
            padding: 11rem 6rem;
            width: 50%;
            color: white;
            }
            .title{
                font-weight: 800;
                font-size: 24px;
                text-align: center;
                line-height: 29px;
                color: #FFFFFF;
              @include desktop{
                font-weight: 800;
                font-size: 48px;
                line-height: 64px;
                text-align: left;
                color: #FFFFFF;
              }
            }
            .description{
                margin-top: .5rem;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: #FFFFFF;
                text-align: center;
                @include desktop{
                margin-top: .5rem;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                color: #FFFFFF;
                text-align: left;
                }
            }
        }


        .testimonial__section{
            width: 100%;
            background: rgba(94, 96, 206, 0.1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include desktop{
            width: 50%;
            background: rgba(94, 96, 206, 0.1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            }
            .testimony{
                background: #FFFFFF;
                box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
                width: 90%;
                margin: 0rem auto 2rem auto;
                padding: 1.3rem;
                min-height:482px;
                @include desktop{
                background: #FFFFFF;
                box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
                width: 90%;
                padding: 1.3rem;
                margin: 0rem;
                
                }
                .control{
                    margin: 4rem 2rem;
                    display: flex;
                    gap: 1.6rem;
                    justify-content: flex-end;
                }
               
            }
            .user__section{
                height: 200px;
                .comment{
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 26px;
                    color: #18191F;
                    margin-top: .9rem;
                    @include desktop{
                    margin-top: .9rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 32px;
                    color: #18191F;
                    }
                }
            }
            .user__info{
                display: flex;
                gap: .8rem;
                .name{
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 18px;
                    color: #18191F;
                    @include desktop{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    color: #18191F;
                    }
                }
                .location{
                    margin-top: .2rem;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #474A57;
                    @include desktop{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #474A57;
                    }
                }
            }
        }
    }
.footer__nav{
    // display: grid;
    // grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    .flex__item {
        flex: 1 0 auto;
        padding: 10px;
        border: 1px solid #ccc;
        margin: 5px;
      }
     
}
    .cta__wrapper{
        background: white;
        padding: 1rem;
        @include desktop{
        background: #F2F2F2;
        padding: 10rem 6rem;

        }
        .cta__banner{
            background: #000000;
            // background-size: contain;
            // background-repeat: no-repeat;
            // background-position: center;
            border: 1px solid #EFF0F7;
            box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
            border-radius: 20px;
            @include desktop{
            box-sizing: border-box;
            height: auto;
            min-height: 418px;
            width: 100%;
            margin: 0px auto;
            max-width: 1164px;
           // background:url('/assets/images/cta-bg.png'), #000000;
            // background-size: cover;
            // background-position: center;
            // background-repeat: no-repeat;
            border: 1px solid #EFF0F7;
            box-shadow: 0px 5px 14px rgba(8, 15, 52, 0.04);
            border-radius: 20px;
            }
        }
        .cta{
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            //align-items: center;
            padding: 2rem;
            @include desktop{
            display: flex;
            justify-content: space-between;
            //align-items: flex-start;
            height: auto;
            min-height: 418px;
            
            padding:0rem 4.1rem;
            flex-direction: row;
            }
            .cta__button__wrapper{
                align-self: center;
                
            }
            .cta__image__wrapper{
                display: flex;
                gap: 2rem;
                flex-wrap: wrap;
                justify-content: center;
                
                .phone__up{
                   align-self: flex-end;
                    justify-self: flex-end;
                   
                }
            }
            .title{
                font-weight: 800;
                font-size: 24px;
               
                line-height: 32px;
                text-align: left;
                color: #FFFFFF;
                margin-bottom: 1.5rem;
                .first__line{
                    &::after{
                        content: ' ';
                      
                    }
                }
                @include desktop{
                font-weight: 800;
                font-size: 38px;
                line-height: 52px;
                text-align: left;
                color: #FFFFFF;
                .first__line{
                    &::after{
                        content: '\a';
                        white-space:pre; 
                    }
                }
                }

            }
        }
    }
    .community__section{
        padding: 3rem;
        background: #0B0D17;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text{
            font-weight: 800;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 2rem;

        }
    }
    
   

    //support page
    .fag__text{
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
    }
    .fag__list{
        li{
            list-style-type: decimal;
        }
    }
    .accordion__header{
        line-height: 29px !important;
    }
    
    .hero__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
        gap: 2rem;
        margin: 2rem auto auto;
        @include desktop{
            margin: 5rem auto auto;
           
        }
        .section__left{
            margin-top: 2rem;
                text-align: center;
                @include desktop{
                text-align: left;
                }
               
                .header__text{
                    transition: opacity 5s;
                    text-align: left;
                    font-weight: 800;
                    font-size: 36px;
                    line-height: 48px;
                    color: #18191F;
                    .first__line, .second__line{
                        &::after{
                            content: ' ';
                            //white-space: pre;
                        }
                    }
                   
                    @include desktop{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 56px;
                    line-height: 75px;
                    color: #18191F;
                    text-align: left;
                    width: 680px;
                    .first__line, .second__line{
                        &::after{
                            content: '\a';
                            white-space:pre; 
                        }
                    }
                    
                  
                    }
                    
                }
                .description__text{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 29px;
                    text-align: left;
                    color: rgba(24, 25, 31, 0.7);
                    margin: .5rem 0rem;
                }
                .action__button{
                    margin: 2rem 0rem !important;
                }
                
            }
        
        .section__right{
           
            img{
                height: 100%;
                width: 100%;
            }
        }
    }
    
    @keyframes slide {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }
      
      .logos {
            div:nth-child(2) {
                display: none;
              }
        @include desktop{
            overflow: hidden;
            padding: 0px 20px 0px 0px;
            background: white;
            white-space: nowrap;
            position: relative;
            div:nth-child(2) {
            display: inline-block;
          }
        }
      }
      
      
      .logos:hover .logos-slide {
        animation-play-state: paused;
      }
      
      .logos-slide {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        gap: 1.4rem 2.6rem;
       justify-content: center;
       align-items: center;
        padding:1rem 0rem;
        margin: 3rem auto;
        @include desktop{
            animation: 35s slide infinite linear;
            display: inline-block;
            margin-top: 1rem;
   
        }
      }
      
      .logos-slide img {
        margin: 0;
        @include desktop{
            margin: 0 20px;
        }
      }
      .video__player{
            box-sizing: border-box;
            position: relative;
            width: 90%;
            height: auto;
            max-height: 410px;
            margin: 2rem auto;
            @include desktop{
                margin: 5rem auto;
                width: 100%;
                max-width: 900px;
                height: auto;
            }
            .video__title{
                color: #18191F;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
                margin-bottom: 1rem;
                @include desktop{
                    font-size: 48px;
                    font-weight: 900;
                    line-height: 65px;
                    margin-bottom: 1rem;
                }
            }
        .thumbnail__wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            
            .play__wrapper{
               margin-top: 15%;
               @include desktop{
                margin-top: 28%;
               }
               
            }
            .watch{
                color: #FFF;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
            }
            
        }
      }
}
