.verify__premium__payment{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .payment__wrapper{
        width: 100%;
        margin: .5rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include desktop{
            width: 54%;
        }
        .status__text{
            margin: .5rem auto;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #21252A
        }
        .message__body{
            margin-bottom: 2rem;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #21252A;
            span{
                font-weight: 600;
            }
        }
    }
}