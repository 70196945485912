.plan-type{
    border: 1px solid #FE8F01;
    border-radius: 2px;
    height: 38px;
    align-items: center;
    justify-content: center;
  
}
.plan td{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #18191F;
}
.active-plan{
background: rgba(254, 143, 1, 0.15);
border-radius: 0px 2px 2px 0px;
height: 36px;
cursor: pointer;
}
.inactive-plan{
cursor: pointer;
height: 36px;

}

.plan-header{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #18191F;
  margin-bottom: 8px;
}

.upgrade .custom-radio {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  .upgrade .custom-radio::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em red;
  }
  .upgrade .custom-radio:checked::before {
    transform: scale(1);
   
  }

  .upgrade input[type="radio"]{
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.65rem;
}

.summary-wrapper{
  height: 100%;
  max-height: 495px;;
}

