.nsHeader {
  background-color: $black;
  width: 100vw;
  height: 64px;
  margin: 0px auto;
  padding: 0px 24px;
  @include desktop {
    padding: 0px;
  }

  &-main {
    width: 100%;
    height: inherit;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // padding: 0px 24px;
    @include desktop {
      width: 1024px;
    }

    & .button {
      font-size: $font-size-1;
    }

    &-title {
      font-size: $font-size-2;
      @include medium-font;
      color: $black;
      @include desktop {
        font-size: $font-size-5;
      }
    }
  }

  &-logo {
    cursor: pointer;
  }

  &-nav {
    display: none;
    @include desktop {
      display: flex;
      flex-direction: row;
    }
    &__item {
      font-size: $font-size-2;
      font-weight: 500;
      color: $white;
      padding: 8px 16px;
      border-radius: 2px;
      background-color: transparent;
      transition: all ease-in-out 0.34s;
      color: $white !important;
      text-decoration: none;
      a {
        color: $white !important;
        text-decoration: none;
        &:hover {
          color: $white !important;
        }
      }

      &:hover {
        color: $white !important;
        text-decoration: none;
      }
      &-alternate {
        @include medium-font;
        &:hover {
          background-color: transparent;
        }
      }
      &.active {
        background-color: rgb(62, 74, 97);
        @include medium-font;
      }
      &-profile {
        display: none;
        @include desktop {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
        }

        &__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;

          & .icon {
            &:first-child {
              // margin-right: 12px;
              @include desktop {
                display: none;
              }
            }
          }
          .avatar {
            cursor: pointer;
            margin-left: 12px;
            display: none;
            @include desktop {
              margin-left: 0px;
              display: flex;
            }
          }
          @include desktop {
            &:hover {
              .nsHeader-profile__dropdown {
                display: block;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &__current-link {
      font-size: $font-size-2;
      @include medium-font;
      color: $black;
      @include desktop {
        display: none;
      }
    }

    &--auth {
      display: flex;
      flex-direction: row;
      align-items: center;

      & .button {
        margin: 0px 8px;
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  &-profile__dropdown {
    // display: block;
    display: none;
    width: 312px;
    height: 100vh;
    border-radius: 0px;
    background-color: $white;
    position: fixed;
    top: 0px;
    right: 0;
    z-index: $z-index-2;
    transition: all 12s ease-in-out;
    opacity: 1;

    @include desktop {
      display: none;
      position: absolute;
      width: 360px;
      height: auto;
      right: 0;
      top: 30px;
      height: auto;
      border-radius: 4px;
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
      padding: 16px;
      opacity: 0;
    }

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      opacity: 0.7;
      z-index: $z-index-1;
      background-color: $overlay;

      display: none;

      @include desktop {
        display: none;
      }
    }

    & .dropdown {
      &__user-details {
        display: flex;
        flex-direction: row;
        margin: 16px 0px;
        padding: 0px 24px;

        .avatar {
          display: flex;
          margin-right: 10px;
        }

        &__email,
        &__role {
          @include medium-font;
          font-size: $font-size-2;
          color: $black;
        }
        &__role {
          font-family: $primary-font;
          font-weight: normal;
          font-size: $font-size-0;
        }
      }

      &-quick__links {
        padding: 0px 16px;
        @include desktop {
          display: none;
        }
      }

      &__base {
        background-color: $whitesmoke;
        padding: 0px 16px;
        height: 100%;
        @include desktop {
          background-color: transparent;
          padding: 0px;
          height: auto;
        }
      }
      &__list {
        // background-color: $whitesmoke;
        // @include desktop{
        //     background-color: transparent;;
        // }
        &-item {
          padding: 8px 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 4px 0px;
          height: 40px;
          border-radius: $border-radius-2;
          background-color: transparent;
          transition: 0.3s all ease-in-out;
          font-size: $font-size-2;
          color: $black;
          cursor: pointer;
          text-decoration: none !important;
          @include medium-font;

          &:hover {
            background-color: $whitesmoke;
            text-decoration: none !important;
          }

          &:first-child {
            margin-top: 0px;
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        &-header {
          padding: 8px 0px;
          margin: 8px 16px 16px 16px;
          height: 40px;
          border-bottom: 1px solid $border-color-1;
          font-family: $semibold-font;
          color: $orange;
          font-size: $font-size-1;
          // &:first-child {
          //     @include desktop{
          //         display: none;
          //     }
          // }
        }
      }
    }
  }

  &-alternate {
    background-color: $white;
    width: 100vw;
    height: 48px;
    padding: 0px 24px;
    position: relative;

    @include desktop {
      padding: 0px;
    }
    &-nav {
      &__item {
        color: $black;
        font-weight: normal;
        padding: 12px 16px;
        border-radius: 0px;
        background-color: transparent;
        transition: all ease-in-out 0s;
        border-bottom: 2px solid transparent;

        color: $black !important;
        text-decoration: none;
        &:hover {
          color: $black !important;
        }

        &.active {
          background-color: transparent;
          @include medium-font;
          border-bottom: 2px solid $orange;
        }
      }

      &__mobile {
        &-overlay {
          position: fixed;
          top: 64px + 48px;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          opacity: 0.7;
          z-index: $z-index-1;
          background-color: $overlay;
          @include desktop {
            display: none;
          }
        }
        position: absolute;
        top: 48px;
        z-index: $z-index-1;
        height: 100vh;
        left: 0;
        right: 0;
        @include desktop {
          display: none;
        }

        &-menu {
          display: flex;
          flex-direction: column;
          background-color: $white;
        }
        // padding: 0px 24px;
        & .nsHeader-alternate-nav__item {
          padding: 12px 24px;
          border-top: 1px solid $border-color-1;
          border-left: 0;
          border-right: 0;
          border-bottom: 0;
          &:last-child {
            border-bottom: 1px solid $border-color-1;
          }
        }
      }
    }

    & .icon {
      margin-left: 24px;
      @include desktop {
        display: none;
      }
    }
    & .button {
      margin-left: auto;
      @include desktop {
        margin-left: unset;
      }
    }
  }
}
