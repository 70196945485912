/* product card */
.productCard {
  margin: 20px 0;
  /* max-height: 350px; */
  height: auto;
  position: relative;
  cursor: pointer;
}

.productCard-link:hover {
  text-decoration: none;
}

.productCard-header {
  aspect-ratio: auto 208 / 208;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
}

.productCard-body {
  padding: 30px 0;
}

.product-name {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  text-transform: capitalize;
  line-height: 2.2rem;
  letter-spacing: 0.4px;
  color: var(--black-color);
}

.product-price {
  font-style: normal;
  font-weight: 800;
  font-size: 1.7rem;
  line-height: 2.4rem;
  letter-spacing: 0.4px;
  color: var(--black-color);
}
.discounted-price {
  color: #495057;
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: line-through;
}
.discount-amount {
  color: #5e60ce;
  background: #f9f5ff;
  font-size: 1.4rem;
  font-weight: 500;
  width: max-content;
  padding: 0.2rem 0.4rem;
}
.product-price-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.add-cart {
  all: unset;
  color: #ffffff;
  background: #fe8f01;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  transform: translateY(50px);
  opacity: 0;
  transition: 1s;
}

.no-stock {
  all: unset;
  color: #ffffff;
  background: #bdbdbd;
  cursor: not-allowed;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  transform: translateY(50px);
  opacity: 0;
  transition: 1s;
}

.cart-icon {
  margin-left: 10px;
  width: 26px;
}

.nostock-icon {
  margin-left: 10px;
  width: 26px;
}

.productCard:hover .add-cart,
.out-of-stock {
  transition: 1s;
  transform: translateY(0px);
  opacity: 1;
  z-index: 1;
}

.productCard:hover .no-stock {
  transition: 1s;
  transform: translateY(0px);
  opacity: 1;
  z-index: 1;
}

.productCard:hover .product-name,
.productCard:hover .product-price {
  color: var(--primary-color);
}
