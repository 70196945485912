
.pricing {
  background: $white;
  &_year {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    column-gap: 1rem;
    margin-bottom: 2rem;
  }
  .plan__wrapper{
    display: flex;
    margin: 3rem auto;
    justify-content: center;
    border: 1px solid rgba(24, 25, 31, 0.5);
    border-radius: 4px;
    width: max-content;
    padding: 4px;
    gap: .5rem;
    .buyer__button{
      padding: 12px 18px;
      border-radius: 4px;
      color: $black;
      cursor: pointer;
    }
    .seller__button{
      padding: 12px 18px;
      border-radius: 4px;
      color: $black;
      cursor: pointer;
    }
    .active__tab{
      background: $purple;
      color: $white;
    }
  }
  .text__small{
    font-size: 14px;
  }
  .pricingTable {
    .seller__plan__wrapper{
    min-height: 400px;
    height: 100%;
    width: 100%;
    margin-inline: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
      min-height: 400px;
      height: 80%;
      width: 100%;
      border: 1px solid rgba(24, 25, 31, 0.5);
      border-radius: 5px;
      margin-bottom: 3rem;
    }
    

    }
    .buyer__plan_wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin: auto;
      padding: 1rem 1rem;

      .plan{
        border: 1px solid rgba(24, 25, 31, 0.5);
        border-right: none;
        border-left: none;
        border-radius: 5px;
        padding-bottom: 3rem;
        padding: auto;
        @include desktop{
        border: 1px solid rgba(24, 25, 31, 0.5);
        border-radius: 5px;
        padding-bottom: 3rem;
        padding: auto;
        }
      }
    }
    .plan__type{
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: $orange;
    }
    &__title {
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 58px;
      color:$black;
      margin-bottom: 1rem;
    }
    & > .pricingTable__list {
      font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin-top: .6rem;
      
      & > .pricingTable__item {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #18191F;
        margin: 1rem 0;
        display: flex;
        column-gap: 0.6rem;

        @include desktop {
          display: flex;
        }
      }
    }

    &__left {
      border-top: 1px solid black;
      border-right: none;
      padding-top: 3rem;
      @include desktop {
        border-top: none;
        border-right: 1px solid black;
      }

      & > button {
        width: max-content;

        @include desktop {
          width: 100%;
        }
      }
    }
    .premium__feature__item{
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 24px;
    }
    &__right {
      border-top: 1px solid black;
      border-right: none;
      padding-top: 3rem;
      @include desktop {
        padding-left: 2rem;
        border-top: none;
        border-right: none;
      }

      & > button {
        width: max-content;

        @include desktop {
          width: 100%;
        }
      }
    }
  }
}
