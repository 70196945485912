.table__wrapper{
    margin-top: 1rem;
    padding-bottom: 6rem;
    overflow-x: auto;
    height: 100%;
    @include mobile{
        overflow: scroll;
       
    }
    .table__border__wrapper{
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
    
    }
    .product__table{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        .product__image{
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin-right: 9px;
        }
        th{
            background: $primary-gray;
            padding: 1rem 1.5rem;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          
        }
        tbody tr{
            padding: .5rem;
            border-bottom:1px solid rgba(0, 0, 0, 0.1) ;
           
            td{
                padding: .6rem 1.5rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
              
                @include desktop{
                    padding: .6rem 1.5rem;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                   
                }
            }
        }
        // @include mobile{
        //     thead {
        //         border: none;
        //         clip: rect(0 0 0 0);
        //         height: 1px;
        //         margin: -1px;
        //         overflow: hidden;
        //         padding: 0;
        //         position: absolute;
        //         width: 1px;
        //       }
              
        //       tr {
        //         display: block;
        //         margin-bottom: .625em;
        //       }
              
        //       td {
        //         border-bottom:1px solid $primary-gray ;
        //         display: block;
        //         font-size: .8em;
        //         text-align: left;
        //         &:last-child{
        //             border-bottom: none;
        //             text-align: right;
        //         }
        //       }
              
        // }
    }
    .table__large{
        tbody tr{
            padding: .2rem;
            border-bottom:1px solid $primary-gray;
            &:last-child{
                border: none;
            }
            @include desktop{
                border-bottom:1px solid $primary-gray ;
                padding: .5rem;
            }
           
            td{
                padding: .6rem 1.5rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
              
                @include desktop{
                    padding: 1rem 1.5rem;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                   
                }
            }
        }
    }
  
     
   }