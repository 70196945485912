.wallet{
    padding: 7px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    @include desktop{
        
    }
.powered{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}

.no-account{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       margin-top: 50px;

    .text{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
    }
       
    .no-account-button{
        width: 90%;
        margin-top:10px;
        background:#311515;
        padding:10px 10px;
        color: #fff;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        outline: none;
        border: none;
    }

}

.wallet-tab{
    display: flex;
    cursor: pointer;
    gap: 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 7px;
    border-bottom: 1px solid gray;
    margin-bottom: 7px;
    @include desktop{
      
  }

  .active{   
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #5E60CE;
    cursor: pointer;
    border-bottom: solid 2px #5E60CE
}

.active{   
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
}
}

.walletbox1{
    width: 100%;
    height: 280px;
    background-image: url('/assets/images/wallet-pattern.png'); // Replace with the path to your image
    background-size: cover; // Adjust as needed
    background-position: center; // Adjust as needed
    background-repeat: no-repeat; // Adjust as needed
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    
   
    .tab-header{
      display: flex;
      gap:10px;
      margin: 15px 5px;
      @include desktop{
       
    }
    }
    .active{
        background:#fff;
        box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
        border-radius: 5px; 
        font-weight: 400;
        font-size: 14px;
        padding: 8px 4px;
        line-height: 12px;
        color: #5E60CE;
        cursor: pointer;
        display: flex;
    }
    .nonactive{
        display: flex;
        border-radius: 8px; 
        font-weight: 400;
        font-size: 14px;
        line-height: 12px;
        padding: 8px 0px;
        color: #fff;
        cursor: pointer;
        display: flex;
    }
    .mywallet{
        color: #fff;
        margin: 0px 10px;
        @include desktop{
            
        }

        .wallet-balance{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }

        .total-balance{
            display: flex;
            align-items: center;
            gap:4px;
            font-weight: 700;
            margin-top: 15px;
            font-size: 24px;
            line-height: 26px;
            cursor: pointer;
           
        }
        .withdraw-button{
            width: 80%;
            margin-top:30px;
            background:#18191F;
            padding:10px 17px;
            color: #fff;
            border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            outline: none;
            border: none;
            @include desktop{
                
                
            }
        }
    }

    .wallet-details{
        color: #fff;
        margin: 0px 10px;
        @include desktop{
            
        }
        .bank{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }  
        .unique-id{
            font-weight: 700;
            font-size: 26px;
            line-height: 22px;
            margin:10px 0px;
            .copy{
                margin-left: 5px;
            }
        }
        .name{
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
        }
        .view-history{
        display: flex;
        align-items:center ;
        justify-content: center;
        background:transparent;
        border: 1px solid #fff;
        box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
        border-radius: 8px;
        height: 34px;  
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        cursor: pointer;
        margin-top: 25px;
        width: 80%;
        @include desktop{
            
            
        }
        }
    }
}

}