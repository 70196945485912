.headerLogo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #464646;
}

.storeName {
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.4rem;
  letter-spacing: 0.4px;
  color: #495057;
}

.bb {
  border-bottom: 1px solid #e3e3e3;
  padding-block: 20px;
}

@media screen and (max-width: 455px) {
  .storeName {
    font-size: 2.2rem;
    text-align: center;
  }
}
