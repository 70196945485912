.on-boarding{
    
    background: $white;
    padding: 24px;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    border-radius: $border-radius-1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include desktop{
        // width: 1024px;
        height: 400px;
        padding: 48px;
    }
    &-wrapper{
        padding-top: 24px;
        padding-right: 16px;
        padding-left: 16px;

        @include desktop {
            padding: 0;
        }
    }

    &-container{
        position: relative;
        top: 24px;
        left: 0;
        right: 0;
        // z-index: 1000;
        // padding-left: 16px;
        // padding-right: 16px;

        @include desktop{
            padding: 0;
        }
        // height: 500px;
    }

    &__content{
        // padding: 48px 0px;
        display: flex;
        flex-direction: column;
    }
    &__title{
        
        font-family: $secondary-font !important ;
        font-size: $font-size-8;
        line-height: 1.25;
        color: $black;
        margin-bottom: 12px;
        text-align: center;

        @include desktop{
            text-align: left;
            width: 317px;
        }
    }
    &__info{
        
        opacity: 0.8;
        font-size: $font-size-2;
        line-height: 1.71;
        color: $dark;
        margin-top: 12px;
        text-align: center;

        @include desktop{
            text-align: left;
            width: 328px;
        }
    }
    &__actions{
        display: flex;
        flex-direction: column;
        margin-top: auto;

        @include desktop {
            flex-direction: row;
        }
    }

    & .button{
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        @include desktop{
            width: 160px;
            margin-top: auto;
            margin-right: 8px;
            margin-left: 8px;
        }

        &:first-child {
            margin-left: 0px;
        }
        &:last-child{
            margin-right: 0px;
            @include desktop{
                width: 184px;
            }
        }
    }

    &-image{
        height: 100%;
        
        &__container{
            height: 100%;
            display: none;

            @include desktop{
                display: block;
            }
            
        }
    }
}