.merchant__store{
    .store__banner{
        background: url('/assets/images/store-banner.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 249px;
        @include desktop{
        background: url('/assets/images/store-banner.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 349px;
        }
       
    }
    .store__logo{
        width: 100%;
        max-width: 180px;
        height: 180px;
        border-radius: 50%;
        background: #D9D9D9;
        margin: -6rem auto 2rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .logo__text{
            font-weight: 600;
            font-size: 64px;
            line-height: 77px;
            letter-spacing: 0.4px;
            color: #464646;
        }
    }
    .store__header__section{
        padding: .1rem 1.5rem;
        position: relative;
        margin: .5rem auto;
        width: 100%;
        max-width: 800px;
        .store__name{
            position: relative;
            text-align: center;
            margin: .1rem;
            font-weight: 700;
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0.4px;
            color: #495057;
            @include desktop{
                position: relative;
                text-align: center;
                margin: .4rem;
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
                letter-spacing: 0.4px;
                color: #495057;
            }
        }
        .store__description{
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.4px;
            color: #495057;
            @include desktop{
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.4px;
                color: #495057;
            }
        }
    }
    .shopping__bag{
        position: absolute;
        width: 48px;
        height: 48px;
        background: $purple;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 3rem;
        top: 45%;
        @include desktop{
            position: absolute;
            width: 68px;
            height: 68px;
            background: $purple;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 3rem;
            top: 75%;
        }
    }
    .search{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.1rem 1.5rem;
        margin: .3rem 2rem;
        @include desktop{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .1rem 1.5rem;
        }
    }
    .product__main__content{
        margin: .4rem auto;
        padding: .1rem 1.5rem;
        width: 100%;
        max-width: 80rem;
        .filter__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            .explore{
                font-weight: 500;
                font-size: 22px;
                line-height: 32px;
                color: #495057;
                @include desktop{
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 39px;
                    color: #495057;
                }
            }
            .filter{
              color: #495057;
            }
        }
        .store__products{
            margin: 2rem auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1rem;
            @include desktop{
                margin: 2rem auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 3rem;
            }
            .product__card{
                
                .product__image__wrapper{
                    margin: 0;
                    width: 100%;
                    max-width: 237px;
                    height: 137px;
                    border-radius: 10px;
                    img{
                        
                        width: 137px;
                        height: 137px;
                        object-fit: contain;
                    }
                    @include desktop{
                    width: 237px;
                    height: 240px;
                    border-radius: 10px;
                    img{
                        
                        width: 237px;
                        height: 240px;
                        object-fit: contain;
                    }
                    }
                }
                .product__info{
                    padding: .5rem 0rem;
                    .product__name{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    @include desktop{
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                    }
                    }
    
                    .product__price{
                    padding: .2rem 0rem;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    @include desktop{
                        padding: .2rem 0rem;
                        font-weight: 800;
                        font-size: 20px;
                        line-height: 24px;
                    }
                    }
                }
            }
        }
        .footer__section{
            margin-top:12rem ;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #495057;
            .footer__links{
                padding:1rem 0rem;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                .quick__link ul{
                    display: flex;
                    gap: 2rem;
                }
                .social__wrapper{
                    display: flex;
                    gap: 1rem;
                    .icon{
                        width: 34px;
                        height: 34px;
                        border: 1px solid #18191F33;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                    }
                }
                
            }
            .footer__wrapper{
                padding:1rem 0rem;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    
}