.pModal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  width: 100%;
  overflow-y: scroll;
  z-index: 800;
  pointer-events: all;

  &-overlay {
    // background-color: $overlay;
    opacity: 0.7;
    background-color: $overlay;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
  }

  &-content {
    margin: 0;
    width: 100%;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    background-color: #ffffff;
    z-index: 1000;
    display: flex;
    flex-direction: column;

    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 65px;
    bottom: 0;

    @include desktop {
      margin: 0px 0px;
      width: 586px;
      height: 85vh;
      // height: fit-content;
      // overflow-y: scroll;
      position: relative;
    }
  }
  &-midcontent {
    @include desktop {
      min-height: max-content;
    }
  }
  &-smallcontent {
    min-height: 200px;
    height: 200px;
  }
  .image-upload > .file-input {
    display: none;
  }
  .image-upload img {
    cursor: pointer;
    height: 200px;
    width: 250px;
    object-fit: cover;
  }
  &-header {
    height: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;

    & .text--small {
      @include medium-font;
    }

    & .icon {
      cursor: pointer;
    }
  }
  &-border-bottom {
    border-bottom: 1px solid rgba(238, 238, 238, 0.643);
  }
  &-border-top {
    border-top: 1px solid rgba(238, 238, 238, 0.643);
  }
  &-sub__header {
    height: auto;
    width: 100%;
    background-color: #ff721b;
    border: solid 1px $gainsboro;
    border-left: none;
    border-right: none;
    @include desktop {
      height: fit-content;
    }

    &-half {
      display: flex;
      flex-direction: column;
      padding: 9px 24px;
      border: solid 1px #fff;
      color: #fff;
      border-bottom: solid 1px $gainsboro;
      // border-left: solid 0.5px #979797;
      justify-content: space-between;
      height: 100%;

      @include desktop {
        border-right: solid 1px $gainsboro;
      }
      &.left {
        border-left: none;
      }
      &.right {
        border-right: none;
        border-bottom: none;
      }

      & .text--smaller {
        color: #fff;
        &:last-child {
          @include medium-font;
        }
      }
    }
  }

  &-main {
    width: 100%;
    padding: 24px;
    @include desktop {
      padding: 0 53px 24px 53px;
    }

    &__notification {
      width: 100%;
      height: 76px;
      padding: 14px 4px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: $border-radius-1;
      @include desktop {
        height: 40px;
      }

      &--small {
        padding: 12px 16px;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        & .icon {
          display: block;
          text-align: left;
          min-width: 14px;
          margin-right: 16px;
        }
        & .text--smallest {
          color: $brown;
          text-align: left;
          display: block;
          @include medium-font;
          //   width: 211px;
        }
      }
    }
    .product-name {
      font-weight: 600;
      margin-left: 3px;
    }
  }

  &-form {
    margin-top: 24px;
    &__label {
      font-size: $font-size-2;
      color: $light-gray;
      &-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;

        @include desktop {
          align-items: flex-end;
        }
      }
    }

    &-control {
      margin: 12px 0px;
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }

    &__datepicker {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      height: 48px;
      border: 1px solid $whitesmoke;
      background-color: transparent;

      & .text--smaller {
        cursor: pointer;
      }
      & .icon {
        margin: 0px auto;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    margin-top: 10px;

    @include desktop {
      justify-content: flex-end;
    }

    & .button {
      height: 40px;
      margin: 0px 8px;
      width: 50%;
      @include desktop {
        width: auto;
      }
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }

    & .button--orange {
      width: 145px;
    }
  }
}
.modal-content{
  margin: 15px !important;
  padding: 0px 10px;
  @include desktop{
    margin: auto;
  
  }
}
.modal__component{
  overflow-y: auto;
.modal__header{
  font-weight: 800;
font-size: 20px;
line-height: 24px;
}
.modal__title{
  margin-top:10px ;
  border-bottom: 0.5px solid rgba(24, 25, 31, 0.09);
  padding: 1.2rem 1rem;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color:$color-black;
}
.code__title{
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 24px;
  margin-top: .6rem;
}
.code__description{
  margin-top: .3rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.title__right{
  text-align: right;
}
.modal__content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:1rem .6rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
  overflow-x: hidden;
  &.modal__full{
    padding: .4rem 1.2rem;
  border-radius: 5px;
  max-width: 700px;
  width: 100%;
  display: block;
  }
}
.modal__control{
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  margin:40px auto;
  &.control__center{
    margin: 30px 20px;
  }
}

.plan__content{
  margin: 1rem auto;
  @include desktop{
      margin: 1rem;
  }
  .plan__item{
      display: flex;
      gap: .2rem 1rem;
      align-items: center;
      padding: .6rem;
      @include desktop{
          gap: 1rem;
      }
      .plan__text{
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #18191F;
          @include desktop{
              font-size: 14px;
              line-height: 28px;
          }
      }
  }
  
}
}

/* SessionExpiredModal.scss */

/* Overlay styles */
.session-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
  transition: transform 0.2s ease; /* Add a subtle animation */

  &.open {
    transform: scale(1); /* Show the modal */
    display: flex;
  }
}

/* Modal container styles */
.session-modal {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  padding: 20px;
  text-align: center;
  position: relative;
 

  /* Header styles */
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  /* Content styles */
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  /* Button styles */
  button {
    background: $purple;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background: #6b05ef;
    }
  }
}

