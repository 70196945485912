.user-account{

    padding: 16px 16px;
    @include desktop{
        padding: 0px;
    }
    &__header{
        display: none;
        margin: 40px 0px;
        .text--md{
            @include medium-font;

        }
        @include desktop{
            display: block;
        }
    }

    padding-bottom: 200px;

    & .pcard-form_control-wrapper {
        @include mobileAndTablet {
            flex-direction: column;
            align-items: unset;
            justify-content: unset;
        }
    }

    & .pcard-body {
        @include mobileAndTablet {
            padding: 0px 16px;
        }
    }


    & .pcard-form_control:last-child,
    & .pcard-form_control:first-child {
        @include mobileAndTablet {
            width: 100%;
            margin: 0;
        }
    }
    & .pcard-header-status {
        margin-left: 16px;
    }
}

.profile-settings{
    display: flex;
    flex-direction: column;
}

.notification{
    &-wrapper{
        // margin-top: 24px;
        
    }
    &-item{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 24px;
        padding-top: 24px;
        @include desktop {
            padding-top: 64px;
        }

        & .notification__list {
            border-bottom: 1px solid $border-color-1;
            padding-bottom: 24px;
            @include desktop{
                padding-bottom: 64px;
            }
        }

        &:first-child {
            padding-top: 0px;
        }
        &:last-child{
            & .notification__list{
                border: none;
                padding-bottom: 0px;
            }
        }

        &__content{
            display: flex;
            flex-direction: column;
        }
    }
    &__list{
        display: flex;
        flex-direction: column;

        &-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 10px;

            [type="checkbox"]:not(:checked)+label:before,
            [type="checkbox"]:checked+label:before,
            [type="checkbox"]:not(:checked)+label:after,
            [type="checkbox"]:checked+label:after {
                top: -6px;
            }
            & .input-control {
                margin-right: 24px;
            }

            &__title {
                font-size: $font-size-2;
                color: $gray;
                margin-bottom: 24px;
                @include desktop{
                    margin-bottom: 0px;
                }
            }

            &-text{
                font-size: $font-size-2;
                color: $black;
            }

        }
    }

}

.bank-account-button{
    @include desktop{
        width: 272px;
    }
}