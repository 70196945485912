@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=IBM+Plex+Sans:wght@100;300;500&family=Inter:wght@100;200;300;400;800&family=Island+Moments&family=Lobster&family=Neonderthaw&display=swap");

@font-face {
  font-family:  "Nunito","Inter";
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  src: url("/assets/fonts/Graphik-Regular-Web.woff") format("woff");
}

@font-face {
  font-family: "Graphik Medium", "Inter";
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  src: url("/assets/fonts/Graphik-Medium-Web.woff") format("woff");
}

@font-face {
  font-family: "Inter","Graphik Semibold";
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  src: url("/assets/fonts/Graphik-Semibold-Web.woff") format("woff");
}

@font-face {
  font-family: "Inter","Merriweather";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Merriweather.woff") format("woff");
}

$primary-font: "Nunito", "Helvetica Neue", Arial, sans-serif;
$medium-font: "Nunito", "Helvetica Neue", Arial, sans-serif;
$semibold-font: "Graphik Semibold", "Inter", "Helvetica Neue", Arial, sans-serif;
$secondary-font: "Merriweather", "Inter", Arial, "Helvetica Neue", Arial, sans-serif;
