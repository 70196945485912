.pcard{
    height: auto;
    border-radius: $border-radius-2;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    background: $white;
    margin: 12px 0px;
    box-sizing: border-box;
    // margin-bottom: 24px;
    &:first-child{
        margin-top: 0px;
    }
    &:last-child{
        margin-bottom: 0px;
    }

    &-header{
        padding: 20px 24px;
        border-bottom: 1px solid $border-color-1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &:first-child{
            &:only-child{
                border-bottom: none;
            }
        }
        // &:has(:nth-child(1)) {
        //     // border-bottom: 1px solid $border-color-1;
        // }
        &__title{
            @include medium-font;
            font-size: $font-size-3;
            color: $black;
        }

        &-status{
            width: 78px;
            height: 24px;
            border-radius: $border-radius-2;
            background-color: $initial-tag;
            @include medium-font;
            font-size: $font-size-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    &-body{
        margin-top: 36px;
        margin-bottom: 56px;

        &-group{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            
        }
    }

    &-footer{
        padding: 16px 24px;
        border-top: 1px solid $border-color-1;
        display: flex;
        flex-direction: row;
        // align-items: flex-end;
        justify-content: flex-end;
    }

    &-form{
        &_control{
            margin: 0px 12px;
            &:first-child{
                width: 40%;
                margin-left: 0px;
            }
            &:last-child{
                width: 60%;
                margin-right: 0px;
            }
            & .nsForm-input__alternate,
            .nsForm-select__alternate,
            .nsForm-textarea {
                @include desktop{
                    width: 272px !important;
                }
            }
            &-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 12px 0px;
                &:first-child{
                    margin-top: 0px;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
}