.pagination__{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #575B68;
    @include desktop{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 3rem;
        margin-top: 2rem;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #575B68;
    }
    .per__page__wrapper{
        display: flex;
        align-items: center;
        gap: 1rem;
        .pagination__filter__select{
            padding: 5px 30px;
            border: none;
            background-position: 90% center;
            &:focus{
                border: none;
                outline: none;
            }
            &:active{
                border: none;
                outline: none;
            }
        }
    }
    .page__number__wrapper{
        display: flex;
        align-items: center;
        gap: 1rem;
        @include desktop{
            display: flex;
            align-items: center;
            gap: 2.125rem;
        }
        .page__control{
            display: flex;
            align-items: center;
            gap: 1rem;
            .control{
                width: 30px;
                height: 30px;
                background: #E8E8EB;
                display: flex;
                outline: none;
                border: none;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
                &:hover{
                    border:1px solid $purple;
                   
                }
                &:disabled{
                    cursor: not-allowed;
                    opacity: .65;
                   
                }
            }
        }
    }
}