.verify__email{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    margin: 2rem auto;
    height: 100vh;
    .message__header{
        padding: .5rem 0rem;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
    }
}