.my__account{
    font-family: 'Inter';
    
    .account_heading{
        padding-top: 1rem;
        font-weight: 600;
        font-size: 24px;
        line-height: 39px;
        color: #18191F;
        @include desktop{
        padding-top: 1rem;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #18191F;
        }
    }
    .header__sub{
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #18191F;
        margin-top: 5px;
    }
    .account__information{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(24, 25, 31, 0.7);

        .name, .email, .address{
            font-weight: 600;
        }
    }
    .button__container{
        display: flex;
        gap: 20px;
        @include desktop{
            display: flex;
            gap: 20px;
        }
        }
        .order__container{
            .title{
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: #18191F;
                margin-bottom: 1rem;
            }
            .table__header{
                background: #F2F2F2;
                
            }
            table,td{
                border-style: none;
                
            }
            
        }
        .profile__container{
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;
            
            .profile__data{
                background: #FFFFFF;
                border: 1px solid rgba(24, 25, 31, 0.15);
                border-radius: 4px;
                .billing__title{
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                }
            }
        }
    }
    
