.plan__notification__banner{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: .3rem 2.2rem;
    flex-wrap: wrap;
    background: linear-gradient(90deg, rgba(254, 143, 1, 0.25) 0%, rgba(254, 143, 1, 0.15) 100%);
    .message{
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: $color-black;
    }
    .premium__button{
        background:$color-black ;
        color: white;
        width: max-content;
        color: $white;
        height: 38px;
        padding: 10px 12px;
        letter-spacing: normal;
        line-height: normal;
        border-radius: $border-radius-2;
        border: 0px;
        outline: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        @include desktop{
            height: 38px;
            padding: 10px 12px;
        }
    }
    .reminder{
        display: flex;
        gap: .4rem;
        .item__box{
            display: flex;
            width: 40px;
            height: 38px;
            padding: 3px 7px 3px 7px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 2px;
            background: rgba(24, 25, 31, 0.15);
            color: $color-black;
         
        
            .label{
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                padding: 0;
            }
            .sub__label{
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
            }
        }
    }
    
}

.premium__state__container{
    display: flex;
    justify-content: center;
    gap: .2rem;
    align-items: center;
    flex-direction: column;
    margin: auto;
    height: 60vh;
    .header{
        color: #21252A;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px
    }
    .description{
        color: #21252A;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px
    }
}