* {
  font-family: "Nunito";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.modal-open{
  padding-right: 0px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input:focus::placeholder {
  color: transparent;
}

html,
body {
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  scroll-behavior: smooth;
  background: $whitesmoke;
}
.bg-info{
  background-color: $purple !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

ul,
li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: inherit;
}
.text__md{
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.fluid-content {
  width: 100%;
}
.max-container {
  max-width: 1440px;
  margin: 0px auto;
}
.max-width {
  width: 100%;
  height: auto;
}
.max-content {
  width: 100%;
  position: relative;
  // overflow-x: hidden;
  min-height: 100vh;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include desktop {
    max-width: 1024px;
    margin: 0px auto;
    min-height: calc(100vh - 112px);
  }
}

.show-mobile-flex {
  display: flex;
}
.show-mobile-block {
  display: block;
}

.hide-mobile {
  display: none;

  @include desktop {
    display: flex;
  }
}

.page-container {
  padding-top: 24px;
  padding-right: 16px;
  padding-left: 16px;
  min-height: 80vh;

  @include desktop {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.spinner {
  animation: spin 2s linear infinite;
}
.ml-15 {
  margin-left: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-20 {
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.columns {
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 -0.9375rem; */
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .md-width-auto {
    width: auto;
  }
  .md-col {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
  .md-col-1 {
    width: 8.333333%;
  }
  .md-col-2 {
    width: 16.666667%;
  }
  .md-col-3 {
    width: 25%;
  }
  .md-col-4 {
    width: 33.333333%;
  }
  .md-col-5 {
    width: 41.666667%;
  }
  .md-col-6 {
    width: 50%;
  }
  .md-col-7 {
    width: 58.333333%;
  }
  .md-col-8 {
    width: 66.666667%;
  }
  .md-col-9 {
    width: 75%;
  }
  .md-col-10 {
    width: 83.333333%;
  }
  .md-col-11 {
    width: 91.666667%;
  }
  .md-col-12 {
    width: 100%;
  }
  .md-col-1,
  .md-col-2,
  .md-col-3,
  .md-col-4,
  .md-col-5,
  .md-col-6,
  .md-col-7,
  .md-col-8,
  .md-col-9,
  .md-col-10,
  .md-col-11,
  .md-col-12 {
    padding: 0 20px;
  }
  .md-full-width {
    width: 100%;
  }
  .md-full-screen {
    width: 100vw;
  }
  /*utilities*/
  .md-mt-20 {
    margin-top: 20px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
  .container-fluid {
    margin-left: 32px;
    margin-right: 32px;
    max-width: none;
  }
  .nav-brand {
    height: 4.375rem;
  }
  .nav > .container-fluid {
    width: 100%;
    display: flex;
    align-items: stretch;
  }
  .nav-left,
  .nav-right {
    display: flex;
    align-items: center;
  }
  .nav-right {
    margin-left: auto;
  }
  .nav-left {
    margin-right: auto;
  }
  .nav-menu {
    width: auto;
    display: flex;
    align-items: center;
  }
  .nav-link,
  .nav-item,
  .nav-brand {
    height: 4.375rem;
    display: flex;
    align-items: center;
  }
  .nav-menu-button {
    display: none;
  }
  .nav-mobile {
    width: auto;
    border-bottom: unset;
  }
  .lg-width-auto {
    width: auto;
  }
  .lg-col {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
  .lg-col-1 {
    width: 8.333333%;
  }
  .lg-col-2 {
    width: 16.666667%;
  }
  .lg-col-3 {
    width: 25%;
  }
  .lg-col-4 {
    width: 33.333333%;
  }
  .lg-col-5 {
    width: 41.666667%;
  }
  .lg-col-6 {
    width: 50%;
  }
  .lg-col-7 {
    width: 58.333333%;
  }
  .lg-col-8 {
    width: 66.666667%;
  }
  .lg-col-9 {
    width: 75%;
  }
  .lg-col-10 {
    width: 83.333333%;
  }
  .lg-col-11 {
    width: 91.666667%;
  }
  .lg-col-12 {
    width: 100%;
  }
  .lg-full-width {
    width: 100%;
  }
  .lg-full-screen {
    width: 100vw;
  }
  .lg-full-height-screen {
    height: 100vh;
  }
  /*utilities*/
  .lg-mx-32 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .lg-mt-20 {
    margin-top: 20px;
  }
  .lg-ml-32 {
    margin-left: 32px;
  }
  .lg-pl-32 {
    padding-left: 32px;
  }
  .lg-pr-40 {
    padding-right: 40px;
  }
  .lg-px-40 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .lg-pt-20 {
    padding-top: 20px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
  .container-fluid {
    margin-left: 2rem;
    margin-right: 2rem;
    max-width: none;
  }
  .nav > .container-fluid {
    width: 100%;
    display: flex;
    align-items: stretch;
  }
  .xl-width-auto {
    width: auto;
  }
  .xl-col {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;
  }
  .xl-col-1 {
    width: 8.333333%;
  }
  .xl-col-2 {
    width: 16.666667%;
  }
  .xl-col-3 {
    width: 25%;
  }
  .xl-col-4 {
    width: 33.333333%;
  }
  .xl-col-5 {
    width: 41.666667%;
  }
  .xl-col-6 {
    width: 50%;
  }
  .xl-col-7 {
    width: 58.333333%;
  }
  .xl-col-8 {
    width: 66.666667%;
  }
  .xl-col-9 {
    width: 75%;
  }
  .xl-col-10 {
    width: 83.333333%;
  }
  .xl-col-11 {
    width: 91.666667%;
  }
  .xl-col-12 {
    width: 100%;
  }
  .xl-full-width {
    width: 100%;
  }
  .xl-full-screen {
    width: 100vw;
  }
  
}
