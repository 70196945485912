.alert{
    width: 100%;
    padding: 24px;
    margin: 0px auto;
    font-size: $font-size-2;
    color: $white;
    text-align: center;
    border: 0px;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    @include medium-font;

    &-icon{
        cursor: pointer;
    }

    &-message{
        font-size: $font-size-2;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        margin: 0px auto;
    }

    &-wrapper{
        width: 100%;
        height: 60px;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
    }
    

    @include desktop {
        max-width: 1440px;
    }

    &-error{
        background-color: $red;
    }
    &-success{
        background-color: $green;
    }
}

.bank-alert {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    background-color: $yellowish;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    margin-bottom: 16px;

    @include desktop {
        // width: 1024px;
    }

    &-main{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    &-icon{
        margin-right: 16px;
    }

    &__message{
        font-size: $font-size-2;
        color: $brown;
        @include medium-font;
    }

    &__action{
        border-radius: $border-radius-md;
        border: 1px solid $brown;
        width: 144px;
        height: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: $font-size-1;
        @include medium-font;
        color: $brown;
        cursor: pointer;

    }
}