.header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;

  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.06);

  @include desktop {
    box-shadow: none;
  }
  .blackText {
    transition: color 0.2s ease-in-out;
    &.blackText:hover {
      color: $lightOrange;
    }
  }

  .active {
    color: $lightOrange;
  }

  .whiteText {
    transition: color 0.2s ease-in-out;
    color: white;
    &.whiteText:hover {
      color: $lightOrange;
    }
  }
  & .navbar__dropdown {
    top: 20px;
    left: 0;
    height: 174px;
    width: 313px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 4px;
    padding: 1rem;
    display: grid;
    grid-template-rows: auto;
    row-gap: 1rem;
  }
}
