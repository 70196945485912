.switch__container{
    input {
        display: none;
      }
    
      label {
        cursor: pointer;
        padding-right: 35px;
        position: relative;
        display: block;
        font-size: 18px;
        padding: 15px 0;
         
      }
    
      input[type="checkbox"], input[type="radio"] {
        position: absolute; 
        visibility: hidden !important;
      }
    
      input[type="checkbox"] + label {
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 60%;
          box-sizing: border-box;
        }
        &:before {
          width: 51px;
          height: 28px;
          right: 0px;
          background: #18191F26;
          border: 1px solid #e4e3e1;
          border-radius: 14px;
          padding:2px;
          top: 16px;
          
          
        }
    
        &:after {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            right: 25px;
            bottom:0px;
            background: white;
            border-radius: 50%;
            content: attr(data-off);
            font-size: 14px;
            transition: all 200ms ease-out;
        }
      }
    
      input[type="checkbox"]:checked + label {
        &:after {
          right: 2px;
          background: white;
          content:  attr(data-on);
          
        }
        &::before{
            background: #5E60CE;
        }
      }
     
      
      
    }
