
.upload__prof{
    .resolution__text{
        font-size: 12px !important;
      }
      //upload prof
      .selected__files{
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin-top: .5rem;
        .file{
            display: flex;
            gap: .6rem;
            flex-wrap: wrap;
            p{
                color: rgb(149, 248, 152);;
            }
            .remove__file{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(149, 248, 152);
                border-radius: 50%;
                color:white;
                width: 18px;
                height: 18px;
                font-size: 12px;
                cursor: pointer;
                &:hover{
                    background-color: rgb(252, 82, 82);
                }
            }
        }
      }
      .file__uploader__wrapper{
        display: flex;
        flex-direction: column;
      }
}