.success__icon{
    width: 100%;
    height: 100%;
    max-width: 130px;
    max-height: 130%;
    object-fit: contain;
    margin-bottom: .4rem;
}
.naira{
    content: "\20A6";
}
.upgrade__plan{
    background: #FFFFFF;
    .title__text{
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #495057;
        margin-top: 3rem;
    }
    .section__title{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #21252A;
        margin-bottom: 1.6rem;
        @include desktop{
            font-size: 20px;
            line-height: 24px;
        }
    }
    .content__wrapper{
        margin: .8rem auto 6rem auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 3rem;
        @include desktop{
            flex-wrap: nowrap;
      
            
        }
       
    }
    .card__info__wrapper{
        width: 100%;
        padding: 1rem;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        @include desktop{
            padding: 2.5rem;
            max-width: 578px;
        }
        
    }

    .billing__summary__wrapper{
        width: 100%;
        height: max-content;
        padding: 1rem;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        @include desktop{
            padding: 2.5rem;
            max-width: 447px;
        }
        .premium__plan__header{
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color:$color-black;
            @include desktop{
                font-size: 16px;
            }
            .digital__title__container{
                display: flex;
                align-items: baseline;
                margin: 0rem .5rem;
                padding: 0rem .5rem;
            }
        }
        .trial__header{
            margin-top: .7rem;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color:$purple;
            @include desktop{
                font-size: 16px;
            }

        }
        .total__summary{
            margin: 2rem auto;
            padding: 1.2rem 0rem;
            border-top: 0.3px solid rgba(0, 0, 0, 0.55);
            border-bottom: 0.3px solid rgba(0, 0, 0, 0.55);
            .total__header{
                display: flex;
                justify-content: space-between;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #21252A;
                @include desktop{
                    font-weight: 600;
                    font-size: 20px;
                }
            }
            .description{
                margin-top: 1rem;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: rgba(24, 25, 31, 0.55);
            }
        }
    }

    .declaimer__section{
        margin-top: 1rem;
        @include desktop{
            margin: 2rem auto;
        }
        .title{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #21252A;
            margin-bottom: .3rem;
            @include desktop{
                font-weight: 600;
                font-size: 20px;
            }
        }
        .description{
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: rgba(24, 25, 31, 0.55);
            @include desktop{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .plan__container{
        margin-top: .6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .plans{
        border: 1px solid #D5DAE1;
        display: flex;
        width: max-content;
        border-radius: 8px;
        .plan__type{
            cursor: pointer;
            padding: 8px 10px;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #D5DAE1;
            flex-wrap: wrap;
            font-size: 14px;
            @include desktop{
                padding: 12px 14px;
                color: #18191F;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
            &:last-child{
                border: none;
            }
            &.active__plan{
                background-color: $purple;
                color: white;
                &:first-child{
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                &:last-child{
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
        
        }
        
    }
    .pricing__container{
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
       width: 100%;
       margin:2rem auto;
       border:1px solid rgba(24, 25, 31, 0.50);
       border-radius: 4px;
       @include desktop{
        grid-template-columns: 1fr 1fr;
        width: 85%;
       }

    }
    .pricing__box{
        padding: 1.5rem;
        &.borderContainer{
            border-bottom:1px solid rgba(24, 25, 31, 0.50);
            border-right:none;
            @include desktop{
                border-right:1px solid rgba(24, 25, 31, 0.50);
                border-bottom:none;
            }
        }
        .common{
            color: $color-black;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            margin-top: 1rem;
            
        }
        
        .price__header{
            display: flex;
            flex-direction: column;
            gap: .6rem;
            .title{
                color: #FE8F01;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
            }
            .digital__title__container{
                display: flex;
                gap: .5rem;
                align-items: center;
                padding: 0rem .5rem;
                @include desktop{
                    align-items: baseline;
                }
                .title{
                    color: $color-black;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    margin-top: 0.875rem;
                }
            }
            .duration{
                color: $color-black;
                font-size: 48px;
                font-style: normal;
                font-weight: 800;
                .period{
                    color: rgba(24, 25, 31, 0.50);
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }
    .plan__list__item{
        display: flex;
        gap: 1rem;
        padding: .4rem .8rem;
        align-items: center;
        .title{
            flex:1;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: $color-black;
            @include desktop{
                line-height: 28px;
            }
        }
    }
    .price__discount{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: .5rem;
        .discount{
            color: #FFF;
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            text-decoration: strikethrough;
            padding: 3px 16px 3px 16px;
            background: #FE8F01;
            border-radius: 4px;
        }
        .discount__period{
            color: rgba(24, 25, 31, 0.70);
            font-size: 16px;
            font-weight: 400;
        }
    }
    
    
}