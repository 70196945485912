.santa__sales{
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    background: #B31312;
    .banner__container{
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @include desktop{
            gap: 2rem;
        }
    }
    .offer__label{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        @include desktop{
            justify-content: flex-start;
        }
        .banner__header{
            display: flex;
            padding: 10px;
            justify-content: center;
            align-items: center;
            border-radius: 45px;
            border: 1px solid #FFF;
            color: #FFF;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            @include desktop{
                font-size: 22px;
                font-weight: 800;
                padding: 10px 20px;
            }
        }
        .title{
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            @include desktop{
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .shop__button{
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        padding: 12px 16px;
        border-radius: 6px;
        background: $color-black;
        outline: none;
        border: none;
        margin-left: 0rem;
        cursor: pointer;
        @include desktop{
            margin-left: 4rem;
            font-size: 16px;
            padding: 16px 20px;
        }
    }
    //countdown item
    .countdown__timer{
        display: flex;
        gap: .8rem;
        align-items: center;
        .countdown__item{
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background: #FFF;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0rem;
            color: $color-black;
            @include desktop{
                width: 54px;
                height: 54px;
            }
            .title{
                font-size: 16px;
                font-weight: 900;
                @include desktop{
                    font-size: 24px;
                font-weight: 900;
                }
                
            }
            .label{
                font-size: 10px;
                font-weight: 300;
            }
    
        }
    }
    
}