.button {
  width: 100%;
  color: $white;
  height: 38px;
  padding: 22px 20px;
  letter-spacing: normal;
  line-height: normal;
  border-radius: $border-radius-2;
  border: 0px;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include desktop{
    height: 48px;
    padding: 16px;
  }
  @include medium-font;
  &:hover {
    text-decoration: none;
  }
  &:focus{
    outline: initial;
    
  }
  
  &:disabled {
    opacity: .7;
    cursor: not-allowed;
  }

  &--auto {
    width: auto;
  }
  &__content{
    width: max-content;
    height: 38px;
    font-size: 13px;
    @include desktop{
      height: 48px;
      
    }
  }
  &-md {
    width: auto;
    height: 32px;
    font-size: $font-size-1;
    padding: 8px 14px;
  }
  &-mid {
    width: 180px;
  }
  &-lg {
    height: 47px;
  }

  &-facebook {
    background: #4367b2;
  }
  &-google {
    background: $white;
    color: $gray;
    border: 1px solid $gainsboro;
  }

  &__rounded {
    border-radius: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  &__radius__sm {
    border-radius: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  &-google,
  &-facebook {
    padding: 12px 16px;
    display: block;
  }

  &__icon {
    margin-right: 20px;
    @include desktop {
      margin-right: 40px;
    }
  }

  &--orange {
    background: $orange;
    &:hover {
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    }
  }
  &--purple {
    background: $purple;
    &:hover {
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    }
  }
  &--green {
    background: rgb(5, 102, 5);
    &:hover {
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    }
  }
  &--orange-outline {
    background: transparent;
    border: 1px solid $orange;
    color: $orange;
  }
  &--purple-outline {
    background: transparent;
    border: 1px solid $purple;
    color: $purple;
  }
  &--orange-border {
    border: solid 1px $orange;
    color: #707479;
  }

  &--grey {
    border: solid 1px #dadada;
    color: #707479;
  }

  &--neutral {
    background-color: $whitesmoke;
    color: #778597;
  }

  &--lighOrange {
    background-color: #fe8f01;
    color: white;
    padding: 18px 36px;
  }
  &--red {
    background: $red;
    &:hover {
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    }
  }

  &--white,
  &--white__with-icon {
    box-shadow: 0 3px 10px 3px rgba(0, 0, 0, 0.04);
    background-color: $white;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &--white__with-icon {
    display: flex;
    flex-direction: row;
    font-size: $font-size-1;
    & .icon {
      margin-right: 16px;
    }
  }
  &--gray {
    background-color: $gray;
    color: $white;
  }
  &--transparent {
    background-color: transparent;
    color: $white;
    border: 1px solid $border-color-1;
    &:hover {
      color: $white;
    }
  }
}
