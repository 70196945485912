.products{
    font-family: 'Nunito';
    padding-bottom: 12rem;
    box-sizing: border-box;
   
    .product__button__wrapper{
        display: flex;
        justify-content: space-between;
        margin: 2rem 0rem 1rem 0rem;
        align-items: center;
        .product__count{
            font-weight: 700;
            font-size: 16px;
            line-height: 29px;
            @include desktop{
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
            }
        }
        
    }
    
   
    
    
    
    
    
    //add products
    .require__field{
        color: #FF0000;
    }
    .row__wrapper{
        display: grid;
        grid-template-columns: 1fr;
        gap: .1rem 3rem;
        @include desktop{
            grid-template-columns: 1fr 1fr;
        }
      
    }
    .product__form__wrapper{
        display: flex;
        flex-wrap: wrap-reverse;
        gap: 0rem;
        @include desktop{
        display: flex;
        flex-wrap: nowrap;
        gap: 4rem;
        }
    }
    .page__title{
        margin: .6rem 0rem;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }
    .border__wrapper{
        border: 1px dashed rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       label{
        width: 100%;
        padding: .5rem;
       }
    }
    .file__upload__wrapper{
        display: grid;
        gap: 1rem 2rem;
        grid-template-columns: repeat(auto-fit, minmax(219px, 1fr)); 
        overflow: hidden;
        // display: flex;
        // gap: .4rem 1rem;
        // align-items: stretch;
        // flex-wrap: wrap;
        // @include desktop{
        //     gap: 1rem 2.5rem;
        // }
        // > *:not(:last-child){
        //     flex: 1;
        // }
        //justify-content: space-between;
    }
    .file__uploader__wrapper{
        display: flex;
        gap: .5rem;
        justify-content: center;
        cursor: pointer;
        min-height: 8.5rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding: 1rem 1.8rem;
        box-sizing: border-box;
        align-items: center;
   

        .upload__button{
            color: $purple;
            cursor: pointer;
        }
        
        .title{
            color: $color-black;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            text-align: center;
        }
        .types{
            font-size: 14px;
            line-height: 19px;
            text-align: center;
        }
        .image__sizing{
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
        }

    }
    
    .image__preview__container{
        //position: relative;
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        .image__preview{
            position: relative;
            display: inline-block;
            &:hover{
                .image__action{
                    opacity: 1;
                }
            }
                img{
                    width: 100%;
                    max-width: 130px;
                    max-height: 131px;
                    border-radius: 4px;
                    position: relative;
                    object-fit: cover;
                    @include desktop{
                    width: 100%;
                    max-width: 160px;
                    max-height: 161px;
                    }
                    
                }
            }
            .image__action{
                width:18px;
                height:18px;
                display:flex;
                justify-content:center;
                align-items:center;
                position: absolute;
                top: 10px;
                right: 10px;
                background: #FFFFFF;
                color: #000000;
                font-size: 18px;
                padding: 8px;
                border-radius: 50%;
                cursor: pointer;
                opacity: 0;
	            transition: opacity 0.2s;
            }
            
        }



    .more__product__detail{
        .detail{
            margin: .2rem 0rem;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-black;
        }
        .product__variant{
            margin: .2rem 0rem;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-black;
            span{
                font-weight: 600;
            }
        }
        .add__more{
            color: $purple;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .add__variant{
        background: $purple;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        margin: 1rem auto .2rem auto;
    }
    .variant__wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: .2rem 1rem;
        padding: 0px;
        margin: .3rem 0rem;
        @include desktop{
            gap:3rem;
            flex-direction: row;
        }
    }
}