.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 1rem;
}
.itemRow {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.itemRow .productImage {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
