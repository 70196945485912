.button {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #000000;
    outline: none;
    border: none;
    background: transparent;
    gap: 10px;
}

.right {
    flex-direction: row-reverse;
}