.dropdown {
    min-width: 100px;
    position:relative;
    display:flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    .dropdown-btn {
        background: none;
        border-bottom-left-radius:4px;
        border-top-left-radius:4px;
        height: 45px;
        width: max-content;
        padding: 0 5px;
        cursor: pointer;
        border: none;

      }
      .bordered{
        border: 1px solid $purple;
        background: #ffff;
        color: $purple;
        width: 135px;
      }
      
      
      .dropdown-items {
        border: 1px solid rgba(24, 25, 31, 0.25);
        box-shadow: 2px 10px 25px 1px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        width: 200px;
        position: absolute;
        margin-top: 46px;
        display: flex;
        flex-direction: column;
        padding: 8px 0px;
        z-index: 99;
        background: #fff;
      }
      .dropdown__item {
        padding: 0px 16px;
        cursor: pointer;
       &:disabled{
        opacity: .3 !important;
        cursor: not-allowed !important;
       }
      }
      .dropdown__item:not(first-child) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .dropdown__item:not(last-child) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      
      .isVisible {
        visibility: visible;
      }
      .isHidden {
        visibility: hidden;
      }
      
      .arrow{
        background: none;
        border-bottom-right-radius:4px;
        border-top-right-radius:4px;
        width: auto;
        display:flex;
        padding: 0px 10px;
        align-items:center;
        cursor: pointer;
      }
      .arrow__bordered{
        background: white;
        border: 1px solid $purple;
        border-left:0px;
      }
      
  }
  
  .dropdown__container{
    position: relative;
    .dropdown__action{
      cursor: pointer;
      margin-bottom: 1rem;
    }
    .dropdown__content{
      position: absolute;
      right: 0rem;
      padding: 1rem 1.4rem;
      min-width: 180px;
      border: 1px solid rgba(24, 25, 31, 0.15);
      box-shadow: 2px 6px 15px rgba(94, 96, 206, 0.09);
      border-radius: 10px;
      background: #ffff;
      z-index: 99;
      
      .dropdown__item{
          display: flex;
          gap: 1rem;
          align-items: center;
          padding: 1.4rem auto;
          margin: 1rem auto;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $color-black;
          cursor: pointer;
          &:last-child{
              color:red;
          }
          &.disabled{
            opacity: .3 !important;
            cursor: not-allowed !important;
          }
          a{
              &:hover{
                  text-decoration: none;
                  color: inherit;
              }
          }
          
      }
  }
  }