.wait__list{
   background: #F5F5F5;
   .intro__wrapper{
    width: 100%;
    margin: 3rem auto;
  @include desktop{
    width: 12rem;
    margin: 0px auto;
  }
    .social__icon{
        margin: 1.3rem 0;
        display: flex;
        gap: 3rem;
        justify-content: center;
        align-items: center;
    }
   }

   .wait__list__wrapper{
    padding: 6rem 0;
   }
    .peppa__intro{
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        color: $color-orange;
        .peppa__text{
            font-weight: 800;
        }
    }
    .title{
        margin:.2rem 0;
        font-weight: 800;
        font-size: 38px;
        line-height: 64px;
        color: $color-black;
    }
    .sub__title{
        margin-top: 2rem;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        padding: 5px 0px;
        color: $color-black;
    }
    .list{
        margin-bottom: 3rem;
        .list__item{
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: $color-black;
            display: flex;
            gap:15px;
            margin: 8px 0px;
        }
    }
}
.wait__list__success{
    min-height: 100vh;
        overflow: hidden;
    .success__wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2px auto;
        height: 500px;
        width: 450px;
        .success__title{
            font-weight: 800;
            font-size: 18px;
            line-height: 20px;
            @include desktop{
            font-weight: 800;
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 1.5rem;
            }
        }
    }
}