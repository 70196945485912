.discounts{
    margin-top: 2rem;
    .title__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .page__title{
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #495057;
        }
    }
    .discount__action{
        display: grid;
        grid-template-columns:  1fr 1fr;
        
        justify-content: space-around;
        align-items: center;
        border:1px solid $purple;
       
        border-radius: 10px;
        .edit__action{
            border-right: 1px solid $purple;
            padding: 12px 15px;
            cursor: pointer;
        }
        .delete__action{
            padding: 12px 15px;
            cursor: pointer;
        }
    }
}