.store-listing{

    .store-header{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: .2rem;
        @include desktop {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding-top: .5rem;
        }
        .title{
            position: relative;
            font-weight: 600;
            font-size: 24px;
            line-height: 39px;
            color: #18191F;
            padding-bottom:.7rem;
            @include desktop{
                position: relative;
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                color: #18191F;
                border-bottom: 0.5px solid rgba(70, 70, 70, 0.45);
                padding-bottom:.7rem;
            }
            
        }
        .thick-line{
            position: absolute;
            top: 3.5rem;
            width: 83px;
            height: 4px;
            background: #FE8F01;
            border-radius: 5px;
        }
    }
    
}