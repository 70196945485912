.store__setup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    padding-bottom: 6rem;
    input{
        background: transparent;
      }

    .complete__setup{
        margin: 2rem 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .image__section{
            img{
                height: 30%;
                width: 30%;
            }
        }
        .header__section{
            margin: 1rem 0rem;
            .title{
                text-align: center;
                font-weight: 800;
                font-size: 24px;
                line-height: 24px;
                color: $color-black;
            }
            .description{
                margin-top: 4px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.4px;
                color: #9598A4;
            }
        }
        .social__connect{
            display: flex;
            justify-content: center;
        }
        .manual__upload{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #5E60CE;
            text-align: center;
            margin-top: .6rem;
            cursor: pointer;
        }
        .skip__upload{
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            padding: .8rem 3rem;
            &:hover{
                background: aliceblue;
            }
        }
    }
    .logo__uploader{
        cursor: pointer;
        width: 100%;
        max-width: 113px;
        height: auto;
        max-height: 102px;
        margin: 1rem auto;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $purple;
        text-align: center;
        .logo__preview{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
        .upload__label{
            border-radius: 25px;
            border: 1px solid #5E60CE;
            padding: 8px 12px;
            cursor: pointer;
        }

        .logo__container{
            cursor: pointer;
            width: 64px;
            color: #FFF;
            font-size: 28px;
            font-weight: 700;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #BDBDBD;
            margin: .5rem auto;
            border-radius: 50%;
            .logo__preview{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .logo__error{
            color:red;
            margin-bottom: 2rem;
            font-size: 14px;
        }
    }
}