@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=IBM+Plex+Sans:wght@100;300;500&family=Inter:wght@100;200;300;400;800&family=Island+Moments&family=Lobster&family=Neonderthaw&display=swap");

.newDashboard {
  .purpleText{
    color: $purple;
  }
  .pricingTable__item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #18191F;
    margin: 1rem 0;
    display: flex;
    column-gap: 0.6rem;
  }
  a:hover{
    text-decoration: none;
  }
  * {
    font-family: "Inter" !important;
  }

  & .setUp {
    font-weight: 800;
    font-size: 28px;
    line-height: 42px;
    width: 80%;
    margin-inline: auto;
    @include desktop {
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      color: #18191F;
      width: 100%;
    }
  }
  background-color: $white;
  scroll-behavior: smooth;
  overflow: hidden;
  .products {
    &__header {
      text-align: center;
      &__title {
        font-style: normal;
        font-weight: 800;
        font-size: 38px;
        line-height: 64px;
        text-align: center;

        @include desktop {
          font-size: 48px;
        }
      }
      &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        width: 80%;
        margin-inline: auto;

        @include desktop {
          width: 100%;
          font-size: 18px;
        }
      }
    }

    &__box__container {
      column-gap: 1rem;
    }
    &__box__content {
      background-color: rgba(254, 143, 1, 0.15);
      border-radius: 4px;
      height: 58px;
      color: $lightOrange;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      transition: all 0.2s ease-in-out;
      min-width: max-content;
      max-width: 340px;
      margin: 1rem auto;
      &include desktop {
        max-width: 100%;
        // width:
      }

      & > a {
        color: $lightOrange;
      }
      & > a:hover {
        text-decoration: none;
      }
    }
    &__box__content:hover {
      box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.7);
    }
    &__main__title {
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 44px;

      @include desktop {
        font-size: 38px;
      }
    }

    &__main__text {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      @include desktop {
        font-size: 16px;
      }
    }

    &__main__button {
      width: 224px;
      font-size: 14px;
    }

    &__main {
      column-gap: 4rem;
    }
  }
}
.business__box {
  // background-color: red !important;
  padding-inline: 0;
  display: flex;
  position: relative;
  height: 256px;
  width: 300px !important;
  & h1 {
    margin-top: 2rem;
    display: block;
  }
}

.laptopGuyContainer {
  gap: 0;
  position: relative;
  height: 400px;
  margin-bottom: 20rem;

  @include desktop {
    margin-bottom: 2rem;
  }

  .laptopGuyLeft {
    background: url("/assets/images/guy-on-laptop.png") no-repeat center center;
    position: relative;
    object-fit: contain;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > .overlay:hover {
      background-color: rgba(0, 0, 0, 0.86);
    }
    & h4 {
      color: $white !important;
      z-index: 1;
      padding-left: 1rem;
      font-weight: bolder;
      width: 100%;
      font-size: 1.4rem;
      @include desktop {
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }

  .laptopGuyRight {
    position: relative;
    & .top {
      background: url("/assets/images/coin-photo.png") rgba(22, 22, 22, 0.7) no-repeat center center;
      position: relative;
      height: 100%;

      & > .overlay:hover {
        background-color: rgba(0, 0, 0, 0.86);
      }

      @include desktop {
        height: 50%;
        // font-size: 2rem;
      }

      display: flex;
      justify-content: flex-start;
      align-items: center;
      & h4 {
        color: $white !important;
        z-index: 1;
        padding-left: 1rem;
        font-weight: bolder;
        width: 50%;
        font-size: 1.4rem;

        @include desktop {
          width: 100%;
          font-size: 1.4rem;
          line-height: 1.9rem;
        }
      }
    }

    & .bottom {
      position: relative;
      background: url("/assets/images/money-map.png") no-repeat center center rgba(22, 22, 22, 0.7);
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > .overlay:hover {
        background-color: rgba(0, 0, 0, 0.86);
      }
      @include desktop {
        height: 50%;
      }
      & h4 {
        color: $white !important;
        z-index: 1;
        padding-left: 1rem;
        font-weight: bolder;
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}
.blackOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  //background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}
.blueOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 159, 253, 0.1);
  overflow: hidden;
  z-index: 0;
}
.footer{
  background-color: #0B0D17;
}
.action-footer{
  h1{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  @include desktop{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
  }
  }
}
.desktopImageSection {
  height: 100%;
  display: flex;
  column-gap: 1rem;
  // justify-content: center;

  @include tablet {
    display: block;
  }
}

.buyer__circle {
  height: 74px;
  width: 74px;
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
}

.pushBtnDown {
  & button {
    margin-top: 28rem !important;
    @include desktop {
      margin-top: 2.5rem !important;
    }
  }
}

.pepperestProtect {
  height: 100%;

  @include desktop {
    height: 280px;
  }
}

.ourValueContainer {
  overflow-x: scroll;
  padding: 2rem;

  // &::-webkit-scrollbar {
  //   height: 0px;
  // }

  @include desktop {
    overflow: hidden;
  }
}

.pageTitle {
  padding-top: 5rem;

  @include desktop {
    padding-top: 3.5rem;
  }
}

.merchantLove {
  font-size: 1.5rem;

  @include desktop {
    font-size: 2.7rem;
  }
}

.ourTeamTitle {
  font-size: 2rem;

  @include desktop {
    font-size: 2.5rem;
  }
}

.whyShouldYou {
  width: 100%;

  @include desktop {
    width: 50%;
  }
}

.MerchantsBox {
  border-radius: 10px;
  width: 380px;
  height: 330px;
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  border-radius: 8px;
  margin-inline: auto;
  @include desktop {
    width: 445px;
    margin-inline: 0;
  }
}

.company {
  & > #aboutUs {
    height: 420px;

    @include desktop {
      height: 500px;
    }
  }

  & .companyFirstSection {
    height: 100vh;

    @include desktop {
      height: 700px;
    }

    & > .mobileVision {
      margin-bottom: 2rem;
    }
  }

  & .ourValuesContainer {
    margin-top: 6rem;
    top: 60%;
    @include desktop {
      margin-top: 3rem;
      top: 63%;
    }
  }
  & .secondSection {
    background-image: url("/assets/images/pattern.png");
    min-height: 430px;
    background-color: rgba(254, 143, 1, 0.1);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    position: relative;
    @include desktop {
      height: 430px;
    }
  }
}

.ourTeamSection {
  height: 100%;
  background-color: white;
  padding-top: 4rem;
  padding-bottom: 4rem;

  & .ourTeamCard {
    max-width: 345px;
    height: 326px;
    border-radius: 8px;

    & .text {
      color: #18191f;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
    }
  }
}

.buyerSection {

  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  position: relative;
  background-image: url("/assets/images/mobile-buyer-bg.png");

  @include desktop {
    background-image: url("/assets/images/buyer-bg.png");
  }
}

.homeSection {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  position: relative;
  background-image: url("/assets/images/mobile-home-bg.png");

  @include desktop {
    background-image: url("/assets/images/homeBg.png");
  }
}
.community-section{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;

  @include desktop{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #000000;
  }
}

.connect-social{
 
  @include desktop{
    margin: 3rem auto 6rem auto;
  }
}
.hero-image{
  width: 100%;
  height: 100%;
  
}
.home-hero-section{
  background: #F2F2F2;
  @include desktop{
    background: #F2F2F2;
    height: 100vh;
    
  }
  
  
 @include desktop{
  background: #F2F2F2;
  background-image: url("/assets/images/new-hero-image.png");
  background-repeat: no-repeat;
  background-size:contain;
  background-position:right top;

 }
  // margin-right: auto;
  // margin-left: auto;
  // @include desktop {
  //   width: 1170px;
  // }
  // @include tablet{
  //   width: 970px;
  // }
  // @include mobile{
  //   width: 750px;
  // }
}

.hero-title{
box-shadow: none;
text-shadow: none;
font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 54px;
text-align: center;
color: #18191F;
margin-top: 5rem;

  @include desktop {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 82px;
  text-align: left;
  color: #18191F;
}
}
.hero-text-description{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #18191F;

  @include desktop {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
text-align: left;
line-height: 32px;
color: #18191F;
  }
}
.register-button{  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 36px;
  width: max-content;
  height: max-content;
  background: #5D5FCC;
  border-radius: 10px;
  font-family: 'Inter';
 
}
.action-button{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom:4rem ;
  align-items: center;

  @include desktop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  }
}

.save-time{
  width: 100%;
  height: auto;
  max-width: 38.438rem;
  max-height: 46.313rem;
  background: rgba(157, 68, 181, 0.1);
  border-radius: 15px;
  img{
    width: 100%;
    height: auto;
    object-fit: contain;
    padding: 0px;
    margin: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    
  }
  
}
.save-time-text{
  box-sizing: border-box;
  .header{
    box-shadow: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;

    @include desktop{
      font-family: 'Inter';
      
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: #18191F;
    }
  }
  .content li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    list-style-type: disc;
    color: #18191F;

    @include desktop{
      font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #18191F;
    list-style-type: disc;
    }

  }
}
.convenience{
  width: 100%;
  height: auto;
  max-width: 615px;
  max-height: 741px;
  background: rgba(7, 42, 200, 0.09);
  border-radius: 15px;
  img{
    width: 100%;
    height: auto;
    object-fit: contain;
    padding: 0px;
    margin: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}
.convenience-text{
  box-sizing: border-box;
  .header{
    box-shadow: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;

    @include desktop{
      font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: #18191F;
    }
  }
  .content li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    list-style-type: disc;
    color: #18191F;

    @include desktop{
      font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #18191F;
    list-style-type: disc;
    }

  }
}



.features-wrapper{
  margin-top: 12rem;
}
.sales-section {
  margin-top: 3rem;
  gap: 30px;
  background: rgba(254, 143, 1, 0.1);
  border-radius: 15px;
  @include desktop{
    margin-top: 6rem;
    gap: 45px;
    background: rgba(254, 143, 1, 0.1);
    border-radius: 15px;
  }

  .header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #18191F;
    box-shadow: none;

   @include desktop{
    box-shadow: none;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    color: #18191F;
   }
  }
  .content li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #18191F;
    list-style-type: disc;
    

    @include desktop{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #18191F;
      list-style-type: disc;
    }

  }
  .sales-image img{
    width: 100%;
    padding: 0px;
    margin: 0px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    @include desktop{
    width: 100%;
    padding: 0px;
    margin: 0px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0px;
    
    }
  
    
  }
}

.pricingTable__item {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin: 1rem 0;
  display: flex;
  column-gap: 0.5rem;

  @include desktop {
    display: flex;
  }
}

.companyHeaderText {
  font-size: 18px;
  @include desktop {
    font-size: 24px;
  }
}

.hero {
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
    @include desktop {
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 64px;
      line-height: 82px;
      /* or 128% */
      
      text-align: center;
    }
  }
  &__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    @include desktop {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      /* or 133% */

      text-align: center;
    }
  }
}

.pricingTable__item {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin: 1rem 0;
  display: flex;
  column-gap: 0.5rem;

  @include desktop {
    display: flex;
  }

  
}

.companyHeaderText {
  font-size: 18px;
  @include desktop {
    font-size: 24px;
  }
}

.hero {
  &__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
    @include desktop {
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 64px;
      line-height: 82px;
      /* or 128% */

      text-align: center;
    }
  }
  &__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    @include desktop {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      /* or 133% */

      text-align: center;
    }
  }
  
}
