.deliveryOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deliveryOption .agentContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.deliveryOption .name {
  font-size: 16px;
  font-weight: 400;
}
.deliveryOption .duration {
  color: rgba(24, 25, 31, 0.55);
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
.deliveryOption .price {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
}
.agentImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
