.footerContainer{
    margin-top: 100px;
}
.copyright {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.9rem;
    letter-spacing: 0.4px;
    color: #2C2E2F;
}

.navLinks {
    list-style: none;
    display: flex;
    gap: 20px;

}

.navlink {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.4px;
    color: #2C2E2F;
}

.link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
}

.link:hover {
    text-decoration: none;
    color: var(--primary-color);
}

.link svg {
    width: 25px;
    height: 25px;
}

.link svg path {
    stroke: #2C2E2F;
}

.link svg path:hover {
    stroke: var(--primary-color);
}

.bt {
    border-top: 1px solid #E9E3DB;
    padding-top: 20px;
}

.footer,
.footer2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ul {
    margin-bottom: 0;
}

div.socialIcons {
    display: flex;
    gap: 10px;
    align-items: center;
    width: max-content;
    margin-left: auto;
}

.socialIcons svg {
    width: 35px;
    aspect-ratio: 1;
    cursor: pointer;
}

@media screen and (max-width: 425px) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .socialIcons svg {
        width: 25px;
        aspect-ratio: 1;
        cursor: pointer;
    }
}