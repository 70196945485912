$color_1: black;
$font-family_1: 'Inter';
$orange-color:#FE8F01;

.navBar{
    .categories {
        display: flex;
        gap: 2.063em;
        justify-content: center;
        padding: .5rem 0px; 
        font-family: $font-family_1;
    }
    .menu {
        cursor: pointer;
        
        a {
            &:hover {
                color: $orange-color;
            }
            text-decoration: none;
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 19px;
            
            position: relative;
        }
    }
    .category-menu {
        position: absolute;
        list-style-type: none;
        width: 16rem;
        margin: 5px;
        left: 7px;
        padding: 10px 15px;
        cursor: pointer;
        overflow-y: auto;
        height: 17.5em;
        a {
            text-decoration: none;
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 19px;
            &:hover {
                color: $orange-color;
            }
        }
    }
    .category-menu-item {
        padding: 5px 8px;
        cursor: pointer;
        a {
            text-decoration: none;
        }
    }
    .sub-category-menu {
        margin: 5px 0;
        padding: 10px 15px;
        cursor: pointer;
        width: 200px;
        border: 1px solid red;
        z-index: 5;
    }
}