.storeContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.store {
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}
.storeLogo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.storeName {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: rgba(24, 25, 31, 0.55);
}
.headerText {
  color: #18191f;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 24px;
}

@media screen and (max-width: 425px) {
  .storeContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
