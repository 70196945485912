.user__profile{
    margin: 2rem 0rem 1rem 0rem;
    .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: $color-black;
    }
    .user__profile__tab{
        display: flex;
        flex-wrap: wrap;
        gap: .5rem 2rem;
        padding: 1rem 0rem ;
        @include desktop{
            gap: 2rem;
        }
       .tab{
        padding: .7rem 0rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        transition: all 0.5ms ease-in-out;
        &:hover{
            color: $purple;
            cursor: pointer;
        }
        &.active__tab{
            color: $purple;
            border-bottom: 2px solid $purple;
        }
       }
    }
    .user__profile__content{
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 1rem 1rem;
        width: 100%;
      
        height: auto;
        margin: 1rem auto;
        @include desktop{
            padding: 2rem 3.5rem;
            max-width: 668px;
            max-height: 794px;
        }
        .image__container{
            width: 92px;
            height: 92px;
            background: rgba(1, 111, 185, 0.15);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .logo__preview{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .edit__image__icon{
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background:#5E60CE ;
            border-radius: 50%;
            position:absolute;
            left: 3.7rem;
            top: 3.5rem;
            cursor: pointer;

        }
        
    }
    .button__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 1rem;
    }

    //send broadcast style
    .attachment__wrapper{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.25); 
        padding-bottom: 25px;

        .attachment{
            display: flex;
            gap: .5rem;
            align-items: center;
            color: $color-black;
            font-size: 16px;
        }
    }
}