.couponLabel {
  color: #2c2e2f;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1.2rem;
}
.couponDescription {
  color: #2c2e2f;
  font-size: 16px;
  font-weight: 400;
}
