.order-confirmation{
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 3rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #18191F;

    @include desktop{
        font-family: 'Inter';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #18191F;
    margin-top:auto;
    }

    .order-detail{
        text-align: center;
        .header-text{
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            margin-top: .5rem;
        }
        .order-id{
            font-weight: 600;
        }
    }
}