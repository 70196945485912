.thirdpartyLayout {
  background-color: var(--light);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &-card {
    min-height: 50%;
    overflow-y: auto;
    border-radius: $border-radius-2;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    display: grid;
    grid-template-rows: 0.2fr 3fr 0.7fr;
    width: 85%;
    @include desktop {
      padding: 8px;
      max-width: 400px;
      width: 100%;
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 1px dashed $border-color-1;
      padding: 16px 0px;
    }
    &-middle {
      padding: 8px 10px;
      overflow-y: auto;
    }
    &__base {
      display: flex;
      border-top: 1px dashed $border-color-1;
      padding: 8px 10px;

      &-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        & .button {
          margin: 0px 8px;
          &:first-child {
            cursor: pointer;
            margin-left: 0px;
          }
          &:last-child {
            cursor: pointer;
            margin-right: 0px;
            background: orange;
          }
        }
      }
    }
  }
}
