.santa__sales__stores{
    background: white !important;
    
    .santa__hero__wrapper{
        min-height: 95vh;
        overflow: hidden;
        background-image: url('../../images/santa-gradient.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        padding-bottom: 7rem;
        .santa__hero{
            display: grid;
            grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
            gap: 2rem;
            align-items: center;
            padding-bottom: 5rem;
            @include desktop{
                margin-top: 4rem;
            }
            .text__hero{
               max-width: 100%;
               margin-top: 3rem;
               @include desktop{
                max-width: 813px;
                margin-top: 0rem;
               }
               .header__title{
                font-size: 32px;
                font-weight: 700;
                line-height: 39px;
                box-shadow: none;
                @include desktop{
                    font-size: 54px;
                    font-weight: 800;
                    line-height: 64px;
                }
               }
               .description{
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                margin-right: 0rem;
                margin-top: 16px;
                @include desktop{
                    margin-right: 7rem;
                margin-top: 24px;
                font-size: 20px;
                }
               }
               .shop__now{
                margin-top: 2rem;
               }
               .scroll__container{
                display: flex;
                align-items: center;
                gap: 1rem;
                width: 240px;
                margin-top: 4rem;
                
                color: #434343;
                font-family: DM Sans;
                font-size: 16px;
                font-weight: 400;
                .scroll__arrow{
                    width: 52px;
                    height: 82px;
                    flex-shrink: 0;
                    border-radius: 26.5px;
                    background: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
               }
            }
        .image__hero{
           
            img{
                width: 100%;
                height: 100%;
            }
        }
        }
        
    }
    .santa__banner{
        margin: 0;
        .banner{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .store__wrapper{
        .header__section{
            padding: 1.4rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            @include desktop{
                padding: 3.2rem 1rem;
            }
            .title{
                color: #495057;
                font-size: 24px;
                font-weight: 700;
                @include desktop{
                    font-size: 32px;
                    font-weight: 600;
                }
            }
            .search{
               width: 100%;
              @include desktop{
                max-width: 18.5rem;
              }
                
            }
        }
        .store__category{
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 1rem;
            overflow-x: auto;
            @include desktop{
                padding: 1.5rem 1rem;
            }
            .category{
                display: flex;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                border-radius: 45px;
                border: 1.3px solid #5D5FCC;
                background: #FFF;
                font-size: 14px;
                color: $purple;
                cursor: pointer;
                width: max-content;
                text-wrap: nowrap;
                white-space: nowrap;
                &.active__category{
                    background: $purple;
                    color: white;
                }
            }
        }
        .stores{
            margin: 2rem 0rem;
            padding: 0rem .5rem ;
            @include desktop{
                padding: 0rem;
            }
           
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // gap: 1rem;
            // @include desktop{
            //     grid-template-columns: repeat(auto-fit,minmax(14rem,1fr));
            //     gap: 2rem;
            // }
            
            .store__item__card{
               
                margin: 20px 0;
                /* max-height: 350px; */
                height: auto;
                position: relative;
                .store__image__container{
                    width: 100%;
                    height: 240px;
                    .store__image{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .store__name{
                    .store__title{
                        color: #495057;
                        font-size: 18px;
                        font-weight: 400;
                        margin: .4rem 0rem;
                    }
                }
                
            }
        }
        .pagination__container{
            padding-bottom: 15rem;
           padding-left: 2rem;
          @include desktop{
            padding-left: 0rem;
          }
        }
    }
}