.title {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #344054;
  margin-bottom: 5px;
}

.label {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #344054;
  margin: 0;
  width: 100%;
}

.textInput {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.inputWrapper {
  margin-top: 15px;
}

.checkboxContainer {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.checkboxContainer input {
  cursor: pointer;
 
}
.disabledCoupon{
  cursor: not-allowed;
  opacity: .5;
  pointer-events: none;
}

.selectWrapper,
.textAreaWrapper {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

.selectWrapper .select {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 24px;
  color: #667085;
  margin: 5px 0;
  position: relative;
  background: transparent;
  background-image: url("./../../buyer-assets/img/svg/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 2rem top 50%;
}

.textAreaWrapper .textArea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 14px;
  gap: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 24px;
  color: #667085;
  margin: 5px 0;
  resize: none;
}

.textArea:focus,
.select:focus,
.textInput:focus {
  border-color: var(--primary-color) !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.checkboxWrapper input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxWrapper label {
  position: relative;
  cursor: pointer;
}

.checkboxWrapper label:before {
  content: "";
  -webkit-appearance: none;
  border-radius: 45px;
  border: 1px solid rgba(93, 95, 204, 0.25);
  background: rgba(93, 95, 204, 0.07);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 14px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 2px;
}
.checkboxWrapper.square label:before {
  content: "";
  -webkit-appearance: none;
  border-radius: 5px;
  border: 1px solid rgba(93, 95, 204, 0.25);
  background: rgba(93, 95, 204, 0.07);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 2px;
}
.checkboxWrapper.square input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 6px;
  height: 12px;
  border: solid #5d5fcc;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxWrapper input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 12px;
  width: 6px;
  height: 14px;
  border: solid #5d5fcc;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.couponInputWrapper {
  display: flex;
  border-radius: 5px;
  border: 1px solid rgba(93, 95, 204, 0.25);
  background: rgba(93, 95, 204, 0.07);
  height: 61px;
  align-items: center;
  margin-top: 1.5rem;
}
.couponInputWrapper input {
  border: 0;
  height: 61px;
  background: transparent;
  width: 100%;
}
.couponInputWrapper .title {
  font-size: 16px;
  color: #5d5fcc;
  text-align: center;
  font-weight: 600;
  padding-right: 20px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .checkboxWrapper label:before {
    padding: 10px;
  }
  .checkboxWrapper input:checked + label:after {
    top: 5px;
    left: 8px;
  }
}
