.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 48px;
	width: 100%;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 4px;
    padding: 0px 16px;
    margin-top: 8px;
        outline: $purple !important;
        @include medium-font;
        &::placeholder {
          color: #A3A1A1;
          @include primary-font;
        }
        
        &:focus{
            border:1px solid $purple;
        }
	&:focus-within {
		border: 1px solid #0052cc;
	}
    
	input {
        flex: 1;
        width: auto;
		border: none;
		height: 46px;
		font-size: 14px;
		padding: 4px 0 0 0;
		&:focus {
			outline: transparent;
		}
	}
}

#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 6px;
	margin: 0 8px 8px 0;
	background: #5E60CE;
	.tag-title {
		margin-top: 3px;
	}
	.tag-close-icon {
		display: block;
		width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		color: #0052cc;
		border-radius: 50%;
		background: #fff;
		cursor: pointer;
	}
}

@media screen and (max-width: 567px) {
	.tags-input {
		//width: calc(100vw - 32px);
	}
}