.customers{
    height: 100vh;
    .coming__soon{
        width: 100%;
        margin: 1rem auto;


        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color:$primary-gray;
        @include desktop{
            width: 50%;
        }
    }
}