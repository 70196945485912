.footer {
  background-color: $primaryBlack;
  height: auto;
  min-height: 370px;
  width: 100%;

  &__list {
    list-style-type: none;
  }

  &__header {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $footerGray;
    margin-bottom: 2rem;
  }

  &__item {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: darkgray;
    cursor: pointer;
    margin-bottom: 1rem;
    min-width: 255px;

    & > a {
      text-decoration: none;
      color: darkgray;
    }

    &__soclal {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      cursor: pointer;
      & span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        background: rgba(71, 70, 70, 0.507);
        & > a {
          text-decoration: none;
          color: darkgray;
        }
      }
    }
  }
}
