.store__setting{
    .store__info__box{
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.04);
        padding: 1.5rem;
        margin: 2.6rem 0rem;
        height: auto;
        @include desktop{
            padding: 2rem 2.7rem;
            height: auto;
        }
        .info__container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
        .store__name__container{
            display: flex;
            align-items: center;
            gap: 1rem 2rem;
            flex-wrap: wrap;
            .image__container{
                width: 62px;
                height: 62px;
                background: rgba(1, 111, 185, 0.15);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                .logo__preview{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .store__logo{
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 12px;
            }
            .logo__wrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .edit__image__icon{
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background:#5E60CE ;
                border-radius: 50%;
                position:absolute;
                left: 3.2rem;
                top: 4.4rem;
                cursor: pointer;
    
            }
        }
        .store__banner__container{
            display: flex;
            justify-content: space-between;
            flex-wrap:wrap-reverse;
            
            @include desktop{
                flex-wrap: nowrap;
                min-height: 200px;
             
            }
            .banner__detail{
                max-width: 100%;
                @include desktop{
                    max-width: 30%;
                }
                label{
                    &:focus{
                        outline: none;
                    }
                }
                .banner__desc{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 2rem 0rem;
                }
                
            }
            .banner{
                position: relative;
                width: 100%;
                max-width:516px;
                height: auto;
                max-height: 197px;
                background: rgb(249, 224, 238);
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                   
                }
            }
        }
        
      
        .title{
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: $color-black;
            margin-bottom:.8rem;
        }
        .store__info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .store__link{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .url__box{
                    display: flex;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    font-size: 13px;
                    font-weight: 500;
                    &.disabled{
                        background: #e1e0e0;
                        border: 1px solid #e1e0e0;
                        cursor: not-allowed;
                        color: #18191F59;
                    }
                    @include desktop{
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                .link{
                    border-right: 1px solid rgba(0, 0, 0, 0.15);
                    padding: 8px;
                   @include desktop{
                    padding: 17px;
                   }
                }
                .merchant__link{
                    padding: 8px;
                    @include desktop{
                     padding: 17px;
                    }
                    .store__code{
                        border: none;
                        width: 100%;
                        &:focus{
                            border: none;
                            outline: none;
                        }
                        &.disabled{
                            background: #e1e0e0;
                            cursor: not-allowed;
                            pointer-events: none;
                            color: #18191F59;
                        }
                    }
                }
                .copy__button{
                    padding: 17px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    color: $purple;
                    cursor: pointer;
                }
            }
            .store__link__action{
                display: flex;
                gap: 2rem;
            }
            
        }
        .upgrade__plan__button{
            background: #039855;
            color:white
        }
       
    }
    .store__preferences{
        display: flex;
        flex-direction: column;
        gap: 2rem 0rem;
        justify-content: center;

        .preference{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @include desktop{
                flex-direction: row;
                align-items: center;
                gap: 1rem;
            }
            .preference__content{
                color: $color-black;
                .title{
                    font-size: 16px;
                    font-weight: 500;
                   
                   
                }
                .description{
                    font-size: 14px;
                    font-weight: 400;
                    padding-right: 20px;
                    
                   
                }
            }
        }
    }
    
}