.confirmPassword {
  .purpleText{
    color: $purple;
  }
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  place-items: center;

  & > div:nth-child(1) {
    height: 100vh;
    max-height: max-content;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8rem 1rem;
    width: 100vw;

    @include desktop {
      padding: 2rem;
      max-height: max-content;
      height: fit-content;
      width: 34vw;
    }
    & > header {
      & > h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #343434;
      }

      & > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        margin-top: 0.6rem;
        color: #747474;
      }
    }
  }
}
