.payment{

    // min-height: 100vh;
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
    @include desktop{
        padding-right: 0px;
        padding-left: 0px;
    }
}
.vender__footer{
    margin-top: 4rem;
    background-color: #000000;
    color: #D1D5DB;
    padding: 2rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    .footer__links{
        display: flex;
        justify-content: space-between;
        align-items: center; 
        a{
           &:hover{
            color:$orange
           } 
        }
    }
    .copyright__text{
        text-align: center;
        margin-top: .8rem;
    }
}


//incoming payment page

.incoming__payment{
    background: #FFF;
    height: 100vh;
    overflow-y: auto;
    a{
        &:hover{
            text-decoration: none;
        }
    }
    
    .header__navigation__wrapper{
        background: #FFFFFF !important;
        box-shadow: 0px 6px 10px rgba(24, 25, 31, 0.1) !important;
        padding: .4rem 0rem;
        @include desktop{
            background: none !important;
            box-shadow: none !important;
            padding:1rem 0rem !important;
          
        }
        .logo{
            width: 100px;
            height: 42px;
            @include desktop{
                width: 100%;
                height: 100%;
            }
        }
      
    }
    .next__step{
        .step__header{
            font-size: 18px;
            font-weight: 800;
            color: $color-black;
            margin-top: 3rem;
        }
        .step__container{
            .step{
                font-size: 14px;
                font-weight: 400;
                line-height: 31px;
                list-style: disc;
                margin: 0px 20px !important;
            }
        }
       }
    .payment__header{
        margin-top: 3rem;
        margin-bottom: .8rem;
        .title{
            //margin-bottom:1.5rem ;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: $color-black;
            text-align: center;
        }
        .amount{
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            margin-top: .2rem;
            color: $orange;
            @include desktop{
                font-size: 48px;
                line-height: 64px;
               
            }
            
        }
    }
    .user__detail{
        width: 100%;
        max-width: 677px;
        margin: 1rem auto;
        padding: 1rem 0rem;
        .name{
            font-size: 18px;
            font-weight: 800;
            line-height: normal;
            color: $color-black;
        }
        .description{
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            span{
                font-weight: 700;
            }
        }
    }
    .payment__detail__container{
        box-sizing: border-box;
        width: 100%;
        max-width: 677px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        margin: 1rem auto;
        padding: 1rem 0rem;
       .detail__title{
        padding: 28px auto;
        text-align: left;
        font-weight: 800;
        font-size: 18px;
        line-height: 29px;
        padding: 1rem;
        @include desktop{
            font-size: 24px;
            line-height: 29px;
        }
       }
       
       .button__wrapper{
        width: 100%;
        padding: 1rem;
        margin: 1rem auto;
      
        gap: 3rem;
        padding-bottom: 2rem;
        @include desktop{
            max-width: 400px; 
            display: flex;
            align-items: center;
           gap: 1rem;
            
        }
        button{
            width: 100%;
            @include desktop{
                width: max-content;
            }
        }
        .decline__btn{
            cursor: pointer;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            margin-top: 1rem;
            color: $purple;
            @include desktop{
                margin-top: 0rem;
            }
        }
       }
       .payment__list{
        padding: 2rem auto;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        @include desktop{
            font-size: 16px;
            line-height: 22px;
        }
        .payment__list__item{
            display: flex;
            justify-content: space-between;
            padding: 1rem 1.4rem;
            
        }
       }
       .policy__text{
        margin: 1rem 0rem 1rem 0rem;
        padding: 1rem 1.4rem .1rem 1.4rem;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color:$color-black;
        text-align: center;
        @include desktop{
            font-size: 15px;
        }
        .link{
            color:#5E60CE
        }
       }
       .agree__terms{
        margin: 2rem 0rem;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        @include desktop{
            flex-direction: row;
            gap: 3rem;
        }
        a{
            color: $purple;
            &:hover{
                text-decoration: none;

            }
        }
        
       }
    }
    .banner__wrapper{
        margin-top: 10rem;
    }
    .payment__state__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh;
        overflow-y: auto;
    }
    
}

//track payment 
.track__payment{
    .track__container{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    
        .mobile__track{
            display: flex;
            flex-direction: column;
            margin-top: -18.7rem;
            margin-left: -.7rem;
            .track__status{
                padding: 1rem 0rem;
                &:first-child{
                    padding-top:0rem ;
                }
            
            }
            .sub__text{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
            .title{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
            
            }
        }
        }
        .payment__step{
            margin-left: -.7rem;
            
        .button__wrapper{
            width: 100%;
            padding: 1rem;
            margin: .5rem auto;
            padding-bottom: 2rem;
            @include desktop{
                max-width: 400px;
                display: flex;
                gap: 2rem;
                
            }
            
           }
           
           
    }
    .help__text{
        margin-top: 1rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        .help__link{
            color: #25D366;
        }
       }
    .payment__header{
        .title{
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            text-align: left;
            margin-top: .5rem;
            color: $color-black;
            @include desktop{
                font-size: 48px;
                line-height: 64px;
                text-align: center;
                margin-bottom: 1.4rem;
               
            }
            
        }
    }
    .track__title__container{
        display: flex;
        gap: 3rem;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        @include desktop{
            margin-top: .6rem;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
           justify-content: space-between;
        }
           
    }
    .incoming{
        margin-right: 1rem;
    }
    .received{
        margin-left: 0rem;
        @include desktop{
            //margin-left: .2rem;
        }
    }
    .processed{
        margin-left: .3rem;
        @include desktop{
            margin-left: .1rem;
        }
    }
    .sent{
        margin-left: .1rem;
    }
    

}
//confirm payment
.confirm__payment{
    .notice{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding: .5rem;
        margin: .3rem auto;
        text-align: center;
        @include desktop{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin: .1rem;
        }
    }
   
}
.payment__info{
    .title{
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        @include desktop{
            font-weight: 800;
            font-size: 24px;
            line-height: 36px;
        }
    }
   
       
    
    
    .reference{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 6rem !important;
        margin-bottom: 3rem;
        @include desktop{
            font-size: 18px;
            line-height: 29px;
            margin-top: 6rem;
            margin-bottom: 3rem;
        }
        .code__wrapper{
            display: flex;
            gap: .5rem;
            align-items: center;
            justify-content: center;
        }
        .code{
            font-weight: 700;
            margin-right: .2rem;
            font-size: 24px;
            font-weight: 800;
        }
    }
}
.free__store__banner{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
    @include desktop {
    max-width: 100%;
    height: auto;
    
    }
}
